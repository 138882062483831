/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import classes from './ProductHeader.module.scss';
import regSuccess from '../../../assets/regSuccess.svg';
import leftArrow from '../../../assets/LeftArrow.svg';
import rightArrow from '../../../assets/RightArrowClick.svg';
import { installationStore } from '@Store/installationStore';
import { getUserSFDetails, isEndUser } from 'utils/getUserAccount';
import Hidden from '@mui/material//Hidden';
import { spinnerStore } from '@Store/spinnerStore';
import { dashboardQuery } from './../../dahboard/dashboard.query';
import { useParams } from 'react-router-dom';
const ProductHeader = () => {
  const id =
    localStorage.getItem('CheckedValues') ||
    localStorage.getItem('CheckedValue');
  // const { id } = useParams();
  const email = getUserSFDetails().email;
  // const { data } = dashboardQuery.useProduct(email, ids, true);
  const { data, status, isLoading } = dashboardQuery.useMultiProducts(
    email,
    id,
    true,
  );
  const [slide, setSlide] = useState(0);
  const { setSpinner, isActive } = spinnerStore();
  const [dataLength, setDataLength] = useState(false);
  // const isLoading = dataLength === 'loading';
  useEffect(() => {
    setSpinner(isLoading);
  }, [isLoading]);
  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1);
  };
  const prevSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1);
  };
  useEffect(() => {
    if (data?.length > 1) {
      setDataLength(true);
    } else {
      setDataLength(false);
    }
  }, [data]);

  const steps = installationStore((state) => state.steps);
  const { currentStep } = steps || {};
  const user = getUserSFDetails();
  const plusCount = isEndUser(user.userRole) ? 1 : 0;
  const statusId = localStorage.getItem('statusId');
  const valueFalg = user.userRole === 'CSR' && statusId === '1' ? true : false;
  return (
    <>
      {!valueFalg ? (
        <div className={classes.productHeader}>
          {dataLength & (currentStep <= 4) ? (
            <button onClick={prevSlide} className={classes.arrow}>
              <img src={leftArrow} />
            </button>
          ) : null}

          {currentStep <= 4 + plusCount && !valueFalg
            ? data?.map((eq, idx) => (
                <>
                  <Hidden mdDown>
                    <div>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            className={classes.rotaryHeadSec}
                          >
                            {/* <p className={classes.subHead}>Product Name</p> */}
                            <h2 className={classes.hoverText}>
                              <h2 className={classes.rotaryTitle}>
                                {eq?.productName}
                              </h2>
                              <span className={classes.tooltip}>
                                {eq?.productName}
                              </span>
                            </h2>
                          </Grid>
                          <Grid item xs={6} md={2} sm={3}>
                            {/* <p className={classes.subHead}>Serial Number</p> */}
                            <p className={classes.subDetails}>
                              {eq?.equipmentNumber}
                            </p>
                          </Grid>
                          <Grid item xs={6} md={2} sm={3}>
                            {/* <p className={classes.subHead}>Product Code</p> */}
                            <p className={classes.subDetails}>
                              {eq?.productCode}
                            </p>
                          </Grid>
                          <Grid item xs={12} md={4} sm={6}>
                            {/* <p className={classes.subHead}>Distributor</p> */}
                            <p className={classes.subDetails}>
                              {eq?.distributorName}
                            </p>
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                  </Hidden>
                  <Hidden mdUp>
                    <div
                      key={idx}
                      className={
                        slide === idx ? classes.slide : classes.slide_hidden
                      }
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            className={classes.rotaryHeadSec}
                          >
                            <p className={classes.subHead}>Product Name</p>
                            <h2 className={classes.hoverText}>
                              <h2 className={classes.rotaryTitle}>
                                {eq?.productName}
                              </h2>
                              <span className={classes.tooltip}>
                                {eq?.productName}
                              </span>
                            </h2>
                          </Grid>
                          <Grid item xs={6} md={2} sm={3}>
                            <p className={classes.subHead}>Serial Number</p>
                            <p className={classes.subDetails}>
                              {eq?.equipmentNumber}
                            </p>
                          </Grid>
                          <Grid item xs={6} md={2} sm={3}>
                            <p className={classes.subHead}>Product Code</p>
                            <p className={classes.subDetails}>
                              {eq?.productCode}
                            </p>
                          </Grid>
                          <Grid item xs={12} md={4} sm={6}>
                            <p className={classes.subHead}>Distributor</p>
                            <p className={classes.subDetails}>
                              {eq?.distributorName}
                            </p>
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                  </Hidden>
                </>
              ))
            : !valueFalg && (
                <div className={classes.productSuccessHead}>
                  <div>
                    <img src={regSuccess} alt="regSuccessIcon" />

                    <h2>
                      Congratulations! Your Equipment Has Been Successfully
                      Registered.
                    </h2>
                  </div>
                </div>
              )}
          {dataLength && currentStep <= 4 ? (
            <button onClick={nextSlide} className={classes.arrow}>
              <img src={rightArrow} />
            </button>
          ) : null}
          <div className={classes.halfLeftCircle}></div>
          <div className={classes.halfRightCircle}></div>
        </div>
      ) : null}
    </>
  );
};

export default React.memo(ProductHeader);
