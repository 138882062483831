import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Button } from '@Components/Button';
import { spinnerStore } from '@Store/spinnerStore';
import { dashboardQuery } from './../dashboard.query';
import UnregisterData from '../UnregisterData/UnregisterData';
import NoResultsFound from '@Components/NoRecordsFound/NoResultsFound';
import filterIcon from './../../../assets/dashboardICons/filter-icon.svg';
import classes from './../dashboard.module.scss';
import scrollToTop from 'utils/scrollToTop';
import { TablePaginationGrid } from './../CardsGrid';
import { AllUnregisteredData } from './AllUnregisteredData';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RadioGroup from '@mui/material/RadioGroup';
import { StyledRadioControl } from '@Components/Radio';
import { toast } from 'react-toastify';
import { uniq } from 'lodash';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import commonLimit from 'common/commonLimits';
const AllUnregisteredTab = (
  { selectedEquipmentdata, onChange, onCountChange },
  ref,
) => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [payload, setPayload] = useState('');
  const [searchPayload, setSearchPayload] = useState('');
  const [pageValue, setPageValue] = useState(1);
  const [allchecked, setAllChecked] = useState(selectedEquipmentdata);
  const [searchString, setsearchString] = useState();
  const [open, setOpen] = React.useState(false);
  const { setSpinner, isActive } = spinnerStore();
  const getCurrentDate = () => {
    // const date = new Date();
    // const day = date.getDate();
    // const month = date.getMonth() + 1;
    // const year = date.getFullYear();
    // Create a new Date object for the current date
    const now = new Date();

    // Get the current year, month, and day
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // Months are zero-based
    const day = now.getDate();

    // Format month and day to be two digits (e.g., "01", "09")
    const formattedMonth = month.toString().padStart(2, '0');
    const formattedDay = day.toString().padStart(2, '0');

    // Output the date in YYYY-MM-DD format
    console.warn(`${year}-${formattedMonth}-${formattedDay}`);
    const currentDate = `${year}-${formattedMonth}-${formattedDay}`;
    return currentDate;
  };
  const getStartDate = () => {
    const date = new Date();
    date.setDate(date.getDate() - commonLimit.installationDateBackDateinDays);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    // Format month and day to be two digits (e.g., "01", "09")
    const formattedMonth = month.toString().padStart(2, '0');
    const formattedDay = day.toString().padStart(2, '0');

    // Output the date in YYYY-MM-DD format
    console.warn(`${year}-${formattedMonth}-${formattedDay}`);
    const currentDate = `${year}-${formattedMonth}-${formattedDay}`;

    return currentDate;
  };
  const [startDate, setStartDate] = useState(getStartDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  // const [dateRange, setDateRange] = useState(false);
  const [dateSelection, setDateSelection] = useState('');
  const handleChange = ({ target }) => {
    setDateSelection(target?.value);
  };
  const [value, setValue] = useState(dayjs().toISOString());
  const [endVal, setEndVal] = useState(dayjs().toISOString());
  const [filter, setFilter] = useState(false);
  const formatDate = (date) => date.toISOString().split('T')[0];
  const handleApply = () => {
    filterCheck();
    if (dateSelection === 'thismonth') {
      setStartDate(handleThisMonth());
      // setFilter(true);
      setEndDate(getCurrentDate());
      setEndVal(dayjs().toISOString());
      setValue(dayjs().toISOString());
      handleClose();
    } else if (dateSelection === 'lastmonth') {
      setStartDate(handleLastMonth());
      setEndDate(handleLastmonthEndDate());
      setEndVal(dayjs().toISOString());
      setValue(dayjs().toISOString());
      // setFilter(true);
      handleClose();
    } else if (dateSelection === 'thisyear') {
      setStartDate(handleThisYear());
      setEndDate(getCurrentDate());
      setEndVal(dayjs().toISOString());
      setValue(dayjs().toISOString());
      // setFilter(true);
      handleClose();
    } else if (dateSelection === 'customDateRange') {
      setStartDate(value);
      setEndDate(endVal);
      handleClose();
    } else {
      setFilter(false);
    }
    console.warn(startDate);
  };
  function filterCheck() {
    if (filter) {
      setFilter(false);
    } else {
      setFilter(true);
    }
  }

  // For unregistered data api call
  const { data, status, isSuccess, isLoading, isRefetching } =
    dashboardQuery.useAllUnregisteredProducts(
      page,
      rowsPerPage,
      searchPayload,
      pageValue,
      startDate,
      endDate,
      filter,
    );
  const [pageData, setPageData] = useState([]);
  useEffect(() => {
    setSpinner(isRefetching);
    if (data?.pageNumber === 1) {
      setPageData(data?.equipments);
    }
    console.warn(pageData, 'pageDATA');
  }, [isRefetching, data]);
  useEffect(() => {
    updateCount();
  }, [data]);
  const selectionLimit = 10;
  const updateCount = () => {
    const prevPage = page - 1;
    const recordsPrevPage = prevPage >= 0 ? prevPage * rowsPerPage : 0;
    const currentPagecount = recordsPrevPage + (data?.equipments.length ?? 0);
    onCountChange(currentPagecount);
  };
  const isLoadings = status === 'loading';
  useEffect(() => {
    if (isActive !== isLoadings) {
      setSpinner(isLoadings);
    }
  }, [isLoadings, data, status, page]);

  useImperativeHandle(ref, () => ({
    showView: showView,
    selectAll: selectAll,
  }));

  function showView(recentData) {
    setsearchString(recentData);
    setPayload(recentData);
    setSearchPayload(recentData);
    console.warn(recentData, 'searchUnReg786');
  }

  function selectAll(selectAllCheck) {
    if (selectAllCheck) {
      const allData = data.equipments.map((value) => {
        return value.equipmentNumber;
      });
      const currentPageData = allchecked.filter((item) => {
        return item.page === page;
      });
      const remainingPageData = allchecked.filter((item) => {
        return item.page !== page;
      });
      let modifyData = currentPageData[0] ?? { page: page, data: [] };
      allData.forEach((item) => {
        const existingData = modifyData.data.filter((existingItem) => {
          return item === existingItem;
        });
        if (existingData.length > 0) {
        } else {
          modifyData.data.push(item);
        }
      });

      const selectedDataLength =
        getSelectedDataCount(remainingPageData) + modifyData.data.length;
      if (selectedDataLength > selectionLimit) {
        toast.error(
          'Equipment selection  or registration  is limited to 10 at once.',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        );
        return false;
      }

      // setAllChecked([...allchecked, ...allData]);
      if (modifyData.data.length <= 10) {
        setAllChecked([...remainingPageData, modifyData]);
      }
      return true;
    } else {
      let filteredData = allchecked.filter((item) => {
        return item.page !== page;
      });

      setAllChecked(filteredData);
      return true;
    }
  }
  function getSelectedDataCount(selData) {
    let count = 0;
    selData.forEach((item) => {
      count += item.data.length ?? 0;
    });
    return count;
  }
  const selectedEquipment = (uneq) => {
    const filteData = allchecked.filter((item) => {
      return item.page === page;
    });
    const currentData = filteData[0] ?? { page: page, data: [] };
    const remainingData = allchecked.filter((item) => {
      return item.page !== page;
    });
    const existingData = currentData.data.filter((selItem) => {
      return selItem === uneq;
    });
    const isAlreadySelected = existingData.length > 0;
    const selCount = getSelectedDataCount(allchecked);
    const totalCount = selCount - (existingData.length ?? 0);
    if (totalCount + 1 > selectionLimit) {
      toast.error(
        'Equipment selection  or registration  is limited to 10 at once.',
        {
          position: toast.POSITION.TOP_CENTER,
        },
      );
      return;
    }
    if (isAlreadySelected) {
      const checkedData = [];
      currentData.data.forEach((item) => {
        if (item !== uneq) {
          checkedData.push(item);
        }
      });
      currentData.data = checkedData;
      setAllChecked([...remainingData, currentData]);
    } else {
      let processedData = [...remainingData];
      currentData.data.push(uneq);
      processedData.push(currentData);
      setAllChecked(processedData);
    }
    console.warn(allchecked, 'allchecked--');
  };
  const getCurrentPageselectedData = () => {
    let selectedData = [];
    allchecked.forEach((item) => {
      selectedData = [...selectedData, ...item.data];
    });
    console.warn(allchecked, selectedData, 'allchecked&selectedData');
    return selectedData;
  };
  useEffect(() => {
    const filterValue = [...new Set(allchecked)];
    onChange(filterValue, page, data?.equipments);
  }, [allchecked, data]);

  const handleChangePage = (event, newPage) => {
    if (payload !== '') {
      if (pageValue < data.totalPages) {
        setPageValue(pageValue + 1);
      }
    }

    if (payload !== '') {
      if (newPage < data?.pageNumber - 1) {
        // if (pageValue < data?.pageNumber - 1) {
        setPageValue(pageValue - 1);
      }
    }
    setPage(newPage + 1);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    scrollToTop();
  };

  const [calendar, setCalendar] = useState(false);
  const filterHandler = () => {
    setCalendar(true);
    if (calendar) {
      setCalendar(false);
    } else {
      setCalendar(true);
    }
  };

  const handleThisMonth = () => {
    const now = new Date();
    const currentDate = new Date(now);
    const startDate = new Date(currentDate.setDate(1));
    const thismonth = formatDate(startDate);
    return thismonth;
  };
  const handleLastMonth = () => {
    const date = new Date();
    const currentDate = new Date(date);
    const lastMonthEndDate = new Date(currentDate.setDate(0));
    const startDate = new Date(lastMonthEndDate);
    startDate.setDate(1);
    return formatDate(startDate);
  };
  const getStartOfSixMonthsAgo = () => {
    // Create a new Date object for the current date
    const now = new Date();
    // Calculate the date six months ago
    const sixMonthsAgo = new Date(now.setMonth(now.getMonth() - 6));
    return formatDate(sixMonthsAgo);
  };
  const handleLastmonthEndDate = () => {
    const date = new Date();
    const currentDate = new Date(date);
    const lastMonthEndDate = new Date(currentDate.setDate(0));
    return formatDate(lastMonthEndDate);
  };
  const handleThisYear = () => {
    const date = new Date();
    const currentDate = new Date(date);
    const thisYearDate = new Date(currentDate.setMonth(0));
    const startDate = new Date(thisYearDate.setDate(1));
    return formatDate(startDate);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={classes.AllUnregisteredTabCont}>
      <div className={classes.filterSection}>
        <button
          className={classes.filterBtn}
          // onClick={filterHandler}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <img src={filterIcon} alt="filterIcon" />
          <span>Filter</span>
        </button>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open1}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className={classes.calendarSection}
      >
        <div className={classes.calendarInside}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={handleChange}
            value={dateSelection}
          >
            <div className={classes.radioBtnsWrapper}>
              <StyledRadioControl
                value="thismonth"
                label="This Month"
                onChange={handleThisMonth}
              />
              <StyledRadioControl
                value="lastmonth"
                label="Last month"
                onChange={handleLastMonth}
              />
              <br />
              <StyledRadioControl
                value="thisyear"
                label="This Year"
                onChange={handleThisYear}
              />

              <StyledRadioControl
                value="customDateRange"
                label="Custom Range"
              />
            </div>
          </RadioGroup>

          {dateSelection === 'customDateRange' ? (
            <div className={classes.pickersSection}>
              {/* <div className={classes.pickersSection}>
                <div className={classes.fromPicker}>
                  <p>From</p>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={['year', 'month', 'day']}
                      defaultValue={dayjs(new Date())}
                      placeholder="d/mm/yyy"
                      maxDate={dayjs(new Date())}
                      selected={value}
                      onChange={(value) => setValue(value.toISOString())}
                      // onClick={handleCalStartValue()}
                    />
                  </LocalizationProvider>
                </div>
                <div className={classes.toPicker}>
                  <p>To</p>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={['year', 'month', 'day']}
                      // defaultValue={dayjs(new Date())}
                      placeholder="d/mm/yyy"
                      maxDate={dayjs(new Date())}
                      selected={endVal}
                      onChange={(endVal) => setEndVal(endVal.toISOString())}
                    />
                  </LocalizationProvider>
                </div>
              </div> */}
              <div className={classes.fromPicker}>
                <p>From</p>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  className={classes.dateSelect}
                >
                  <DatePicker
                    sx={{
                      color: '#D2D2D2',
                      borderRadius: '2px',
                      borderWidth: '1px',
                      borderColor: '#D2D2D2',
                      border: '1px solid',
                      backgroundColor: '#fff',
                      // minWidth: '80px',
                      // width: '50%',
                      // height: '38px',
                    }}
                    views={['year', 'month', 'day']}
                    defaultValue={dayjs(value)}
                    // minDate={dayjs(getStartOfSixMonthsAgo())}
                    maxDate={dayjs(new Date())}
                    selected={value}
                    onChange={(value) => setValue(value.toISOString())}
                  />
                </LocalizationProvider>
              </div>
              <div className={classes.toPicker}>
                <p>To</p>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  className={classes.dateSelect}
                >
                  <DatePicker
                    sx={{
                      color: '#D2D2D2',
                      borderRadius: '2px',
                      borderWidth: '1px',
                      borderColor: '#D2D2D2',
                      border: '1px solid',
                      backgroundColor: '#fff',
                      // minWidth: '80px',
                      // width: '50%',
                      // height: '38px',
                    }}
                    views={['year', 'month', 'day']}
                    defaultValue={dayjs(endVal)}
                    minDate={dayjs(getStartOfSixMonthsAgo())}
                    maxDate={dayjs(new Date())}
                    selected={endVal}
                    onChange={(endVal) => setEndVal(endVal.toISOString())}
                  />
                </LocalizationProvider>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className={classes.popupActiveButtons}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setAnchorEl(null);
              }}
              className={classes.cancelButton}
            >
              CANCEL
            </Button>
            <Button
              type="submit"
              className={classes.popupActiveButton}
              onClick={handleApply}
            >
              APPLY
            </Button>
          </div>
        </div>
      </Menu>
      {isSuccess && data && (
        <div className={classes.AllUnregisteredTabCont}>
          <div className={classes.middleContainer}>
            {data?.equipments.map((uneq) => (
              <>
                <AllUnregisteredData
                  equipment={uneq}
                  key={uneq?.equipmentNumber}
                  selectedEquip={getCurrentPageselectedData()}
                  selectedEquipment={selectedEquipment}
                />
              </>
            ))}
          </div>
          {data?.totalRecords > 2 && (
            <div className={classes.pagination}>
              <TablePaginationGrid
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={data?.pageNumber - 1}
                rowsPerPage={data?.pageSize}
                count={data?.totalRecords}
              />
            </div>
          )}
        </div>
      )}
      {isSuccess && !data && <NoResultsFound handleClick={handleOpen} />}
    </div>
  );
};

export default forwardRef(AllUnregisteredTab);
