import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { spinnerStore } from '@Store/spinnerStore';
import { dashboardQuery } from './../dashboard.query';
import UnregisterData from '../UnregisterData/UnregisterData';
import NoResultsFound from '@Components/NoRecordsFound/NoResultsFound';
import classes from './../dashboard.module.scss';
import scrollToTop from 'utils/scrollToTop';
import { TablePaginationGrid } from './../CardsGrid';
import { toast } from 'react-toastify';
import getUserAccount, {
  getUserSFDetails,
  setUserSFDetails,
} from 'utils/getUserAccount';
import { authService } from '@Services/auth';
import { DistributorDashboard } from '../DistributorDashboard';
const UnregisteredTab = ({ selectedEquipmentdata, onChange }, ref) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [payload, setPayload] = useState('');
  const [searchPayload, setSearchPayload] = useState('');
  const [pageValue, setPageValue] = useState(1);
  const [allchecked, setAllChecked] = useState(selectedEquipmentdata);
  const [searchString, setsearchString] = useState();
  const { setSpinner, isActive } = spinnerStore();
  const [open, setOpen] = React.useState(false);
  const [showDistrbutorModal, setShowDistrbutorModal] = useState(false);
  const handleOpen = () => {
    const userSf = getUserSFDetails();
    if (userSf.userRole === 'Distributor' && !userSf.distributorNumber) {
      setShowDistrbutorModal(true);
    } else if (userSf.userRole === 'Installer' && !userSf.installerNumber) {
      setShowDistrbutorModal(true);
    } else {
      setOpen(true);
    }
  };
  const handlePopup = (value) => {
    setShowDistrbutorModal(value);
  };
  const onSucessPopUp = () => {
    setShowDistrbutorModal(false);
    const userAccount = getUserAccount();
    authService.getUserDetails(userAccount.email).then(({ data }) => {
      setUserSFDetails(data.response);
    });
  };
  // For unregistered data api call
  const { data, status, isSuccess, isRefetching } =
    dashboardQuery.useUnregisteredProducts(
      page + 1,
      rowsPerPage,
      searchPayload,
      pageValue,
    );
  useEffect(() => {
    setSpinner(isRefetching);
  }, [isRefetching]);
  const isLoading = status === 'loading';
  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
  }, [isLoading, data, status]);
  useImperativeHandle(ref, () => ({
    showView: showView,
  }));

  function showView(recentData) {
    setsearchString(recentData);
    setPayload(recentData);
    setSearchPayload(recentData);
    console.warn(recentData, 'searchUnReg786');
  }
  const selectedEquipment = (uneq) => {
    const existingData = allchecked.filter((item) => {
      return item === uneq;
    });
    if (existingData.length > 0) {
      const unCheckData = [];
      allchecked.forEach((item) => {
        if (item !== uneq) {
          unCheckData.push(item);
        }
      });
      setAllChecked(unCheckData);
    } else {
      if (allchecked.length >= 10) {
        toast.error(
          'Equipment selection  or registration  is limited to 10 at once.',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        );
        return;
      }
      setAllChecked([...allchecked, uneq]);
    }
  };
  useEffect(() => {
    console.warn(allchecked, '123444');
    onChange(allchecked);
  }, [allchecked]);
  const handleChangePage = (event, newPage) => {
    if (payload !== '') {
      if (pageValue < data.totalPages) {
        setPageValue(pageValue + 1);
      }
    }

    if (payload !== '') {
      if (newPage < data?.pageNumber - 1) {
        // if (pageValue < data?.pageNumber - 1) {
        setPageValue(pageValue - 1);
      }
    }
    setPage(newPage);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    scrollToTop();
  };

  return (
    <div>
      {isSuccess && data && (
        <>
          <div className={classes.middleContainer}>
            {data?.equipments.map((uneq) => (
              <>
                <UnregisterData
                  equipment={uneq}
                  key={uneq?.equipmentNumber}
                  selectedEquip={allchecked}
                  selectedEquipment={selectedEquipment}
                />
              </>
            ))}
          </div>
          {data?.totalRecords > 2 && (
            <div className={classes.pagination}>
              <TablePaginationGrid
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={data?.pageNumber - 1}
                rowsPerPage={data?.pageSize}
                count={data?.totalRecords}
              />
            </div>
          )}
        </>
      )}
      {isSuccess && !data && <NoResultsFound handleClick={handleOpen} />}
      {showDistrbutorModal && (
        <DistributorDashboard
          open={showDistrbutorModal}
          setOpen={handlePopup}
          onSucessPopUp={onSucessPopUp}
        />
      )}
    </div>
  );
};

export default forwardRef(UnregisteredTab);
