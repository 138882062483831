import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import classes from './RotaryAccordion.module.scss';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button } from '@Components/Button';
import Hidden from '@mui/material//Hidden';
import accordionMoreIcon from './../../../assets/dashboardICons/accordionMore.svg';
import accordionLessIcon from './../../../assets/dashboardICons/accordionLess.svg';
import accordionMoreColorIcon from './../../../assets/dashboardICons/accordionMoreColorIcon.svg';
import { RequestForTransfer } from './RequestForTransfer';
import classNames from 'classnames';
import { getUserSFDetails } from 'utils/getUserAccount';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import texaIcon from './../../../assets/dashboardICons/texaIcon.svg';
import touchIcon from './../../../assets/touch_screen.png';
import texaActivated from './../../../assets/Texa_Activated.svg';
import { instalationQuery } from './../../Installation/Installation.query';
import closeIcon from './../../../assets/closeIcon.svg';
import { spinnerStore } from '@Store/spinnerStore';
import { useNavigate } from 'react-router-dom';
import { dashboardQuery } from './../dashboard.query';

export default function CardAccordion({ equipment: uneq }) {
  const [open, setOpen] = React.useState(false);
  const [activatePopup, setActivatePopup] = React.useState(false);
  const [activatedPopup, setactivatedPopup] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [isShown, setIsShown] = React.useState(false);
  const [openAccordionId, setOpenAccordionId] = React.useState(null);
  const handleAccordionChange = (itemId) => {
    setOpenAccordionId(itemId === openAccordionId ? null : itemId);
  };

  const {
    mutate,
    status,
    data: texaDetails,
  } = instalationQuery.useMutationtexaActivate();

  const { setSpinner, isActive } = spinnerStore();
  const convertDate = (string) => {
    let date = new Date(string);
    let dateMDY = `${
      date.getMonth() + 1
    }-${date.getDate()}-${date.getFullYear()}`;
    return dateMDY;
  };
  // console.warn(
  //   status,
  //   texaDetails,
  //   texaDetails?.data?.response,
  //   'texaDetailstexaDetails',
  // );
  const activeProduct = () => {
    setActivatePopup(true);
  };

  const userData = getUserSFDetails();

  const handleTexaActivate = () => {
    const payload = {
      userId: userData.id,
      userEmail: userData.email,
      equipmentId: uneq?.equipmentId,
      equipmentNumber: uneq?.equipmentNumber,
      firstName: userData.firstName,
      lastName: userData.lastName,
    };
    mutate(payload);
    setactivatedPopup(true);
  };
  const handleClose = () => {
    setActivatePopup(false);
  };
  const navigate = useNavigate();

  const handleClosed = () => {
    dashboardQuery.useProducts();
    // setTimeout(() => navigate('/dashboard'), 300);
    setactivatedPopup(false);
    setActivatePopup(false);
    // window.location.reload();
  };
  const activedCode = localStorage.getItem('activatedCode');
  const isLoading = status === 'loading';
  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
  }, [isLoading]);
  return (
    <>
      <Hidden mdDown>
        <div className={classes.rotaryCardContainer}>
          <Accordion
            className={classNames(classes.headingSection, 'accord-wrapper')}
            key={uneq.equipmentNumber}
            expanded={openAccordionId === uneq.equipmentNumber}
            onChange={() => handleAccordionChange(uneq.equipmentNumber)}
          >
            <div
              className={
                openAccordionId === uneq.equipmentNumber
                  ? classes.headingDetailsFocus
                  : classes.headingDetails
              }
            >
              <AccordionSummary
                expandIcon={
                  openAccordionId === uneq.equipmentNumber ? (
                    <img src={accordionMoreColorIcon} alt="accordionLessIcon" />
                  ) : isShown ? (
                    <img src={accordionMoreColorIcon} alt="accordionLessIcon" />
                  ) : !isShown ? (
                    <img
                      src={accordionMoreIcon}
                      alt="accordionMoreIcon"
                      className={classes.iconStyles}
                    />
                  ) : (
                    <img src={accordionLessIcon} alt="accordionLessIcon" />
                  )
                }
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionExpand}
              >
                <div className={classes.rotaryHeading}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={2}
                      className={classes.rotaryHeadingText}
                    >
                      <Grid
                        item
                        xs={6}
                        md={4}
                        className={classes.rotaryHeadingCont}
                      >
                        <p className={classes.subHead}>Product Name</p>

                        <p className={classes.hoverText}>
                          <h2 className={classes.rotaryTitle}>
                            {uneq?.productName}
                          </h2>
                          <span className={classes.tooltip}>
                            {uneq?.productName}
                          </span>
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={4}
                        className={classes.rotaryHeadingCont}
                      >
                        <p className={classes.serialNum}>Serial Number</p>
                        <p className={classes.serialNumSub}>
                          {uneq?.equipmentNumber}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={4}
                        className={classes.rotaryHeadingCont}
                      >
                        <p className={classes.registrationDate}>
                          Date of Registration
                        </p>
                        <p className={classes.registrationDatesub}>
                          {uneq?.registeredDate &&
                            convertDate(uneq?.registeredDate)}
                        </p>
                      </Grid>
                      {uneq.isTexaProduct === true &&
                        uneq.isTexaProductActivated === false &&
                        texaDetails?.data?.response !== 1 && (
                          <div className={classes.activeButton}>
                            <Button type="submit" onClick={activeProduct}>
                              Activate Now
                            </Button>
                          </div>
                        )}
                      {uneq.isTexaProduct === true &&
                        uneq.isTexaProductActivated === true && (
                          <div className={classes.activeText}>
                            <span>
                              <img
                                src={texaActivated}
                                alt="Texa Product Activated"
                              />
                            </span>
                            Activated
                          </div>
                        )}
                    </Grid>
                  </Box>
                </div>
              </AccordionSummary>
            </div>
            <AccordionDetails className={classes.expandedContainer}>
              <Typography>
                <div className={classes.cardDetailSection}>
                  <Box sx={{ flexGrow: 1 }} className={classes.productDetails}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={4}>
                        <p className={classes.subHead}>Product Code</p>
                        <p className={classes.subDetails}>
                          {uneq?.productCode}
                        </p>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <p className={classes.subHead}>Distributor</p>
                        <p className={classes.subDetails}>
                          {uneq?.distributorName}
                        </p>
                      </Grid>
                      {userData.userRole !== 'Basic User' && (
                        <Grid item xs={6} md={4}>
                          <p className={classes.subHead}>Product Owner</p>
                          {userData.userRole !== 'Basic User' && (
                            <p className={classes.subDetails}>
                              {uneq.registeredFor}
                            </p>
                          )}
                        </Grid>
                      )}
                      <div className={classes.transferButton}>
                        <Button type="submit" onClick={handleOpen}>
                          Request for Transfer
                        </Button>
                      </div>
                    </Grid>
                  </Box>
                  <Box
                    sx={{ flexGrow: 1 }}
                    className={classes.installerDetails}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={4}>
                        <p className={classes.subHead}>Installer Type</p>
                        <p className={classes.subDetails}>
                          {uneq?.installerType}
                        </p>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <p className={classes.subHead}>Installer</p>
                        <p className={classes.subDetails}>
                          {uneq?.installerName}
                        </p>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <p className={classes.subHead}>Location</p>
                        <p className={classes.subDetails}>
                          {uneq?.installedLocation}
                        </p>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Hidden>
      <div>
        <Modal
          open={activatePopup === true && texaDetails?.data?.response !== 1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.ModalStyle}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={classes.modalTitle}
            >
              Equipment is Inactive
            </Typography>

            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              className={classes.paraGraph}
              // style={{ textAlign: 'center' }}
            >
              <div className={classes.texaInactiveText}>
                <div>
                  <img src={texaIcon} alt="TexaIcon" />
                </div>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  Your equipment is registered but not activated. Please
                  activate it.
                </div>
              </div>
            </Typography>

            <div className={classes.popupActiveButtons}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                className={classes.cancelButton}
              >
                Activate Later
              </Button>
              <Button
                type="submit"
                className={classes.popupActiveButton}
                onClick={() => {
                  handleTexaActivate();
                }}
              >
                Activate Now
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
      <div>
        <Modal
          open={activatedPopup === true && texaDetails?.data?.response !== 1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.ModalStyle}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={classes.modalTitle}
            >
              Activation Code
              <Link onClick={handleClosed} className={classes.closeIcon}>
                <img src={closeIcon} alt="closeIcon" />
              </Link>
            </Typography>

            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              className={classes.paraGraphcont}
              // style={{ textAlign: 'center' }}
            >
              <div className={classes.texaInactiveText}>
                <div>
                  <img src={touchIcon} alt="Touch Icon" />
                </div>
                <div className={classes.texaActivatedText}>
                  <p>
                    Your Equipment has been activated from our end, Please enter
                    the activation code below during equipment set up
                  </p>
                </div>
                <div className={classes.texaActivatedCode}>{activedCode}</div>
                <span className={classes.texaActivatedCodeSpan}>
                  An email has also been sent with the activation code to your
                  registered email
                </span>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>

      <Hidden mdUp>
        <div className={classes.rotaryCardContainerMobile}>
          <Accordion
            className={classes.headingSection}
            expanded={openAccordionId === uneq.equipmentNumber}
            onChange={() => handleAccordionChange(uneq.equipmentNumber)}
          >
            <div
              className={
                openAccordionId === uneq.equipmentNumber
                  ? classes.headingDetailsFocus
                  : classes.headingDetails
              }
            >
              <AccordionSummary
                expandIcon={
                  openAccordionId === uneq.equipmentNumber ? (
                    <img src={accordionMoreColorIcon} alt="accordionLessIcon" />
                  ) : isShown ? (
                    <img src={accordionMoreColorIcon} alt="accordionLessIcon" />
                  ) : !isShown ? (
                    <img
                      src={accordionMoreIcon}
                      alt="accordionMoreIcon"
                      className={classes.iconStyles}
                    />
                  ) : (
                    <img src={accordionLessIcon} alt="accordionLessIcon" />
                  )
                }
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionExpand}
              >
                <div className={classes.rotaryHeading}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={2}
                      className={classes.rotaryHeadingText}
                    >
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.rotaryHeadingCont}
                      >
                        <p className={classes.subHead}>Product Name</p>
                        <h2 className={classes.rotaryHeading}>
                          {uneq?.productName}
                        </h2>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </AccordionSummary>
            </div>
            <AccordionDetails style={{ border: '1.5px solid #CF8686' }}>
              <Typography>
                <div className={classes.cardDetailSection}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={2}
                      className={classes.rotaryHeadingText}
                    >
                      <Grid
                        item
                        xs={6}
                        md={4}
                        className={classes.rotaryHeadingCont}
                      >
                        <p className={classes.subHead}>Serial Number</p>
                        <p className={classes.subDetails}>
                          {uneq?.equipmentNumber}
                        </p>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <p className={classes.subHead}>Product Code</p>
                        <p className={classes.subDetails}>
                          {uneq?.productCode}
                        </p>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ flexGrow: 1 }} className={classes.productDetails}>
                    <Grid container spacing={2}>
                      {userData.userRole !== 'Basic User' && (
                        <Grid item xs={6} md={4}>
                          <p className={classes.subHead}>Product Owner</p>
                          {userData.userRole !== 'Basic User' && (
                            <p className={classes.subDetails}>
                              {uneq.registeredFor}
                            </p>
                          )}
                        </Grid>
                      )}
                      <Grid item xs={6} md={4}>
                        <p className={classes.subHead}>Distributor</p>
                        <p className={classes.subDetails}>
                          {uneq?.distributorName}
                        </p>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{ flexGrow: 1 }}
                    className={classes.installerDetails}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={4}>
                        <p className={classes.subHead}>Installer Type</p>
                        <p className={classes.subDetails}>
                          {uneq?.installerType}
                        </p>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <p className={classes.subHead}>Installer</p>
                        <p className={classes.subDetails}>
                          {uneq?.installerName}
                        </p>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{ flexGrow: 1 }}
                    className={classes.installerDetails}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={4}>
                        <p className={classes.subHead}>Location</p>
                        <p className={classes.subDetails}>
                          {uneq?.installedLocation}
                        </p>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        md={4}
                        className={classes.rotaryHeadingCont}
                      >
                        <p className={classes.subHead}>Date of Registration</p>
                        <p className={classes.subDetails}>
                          {uneq?.registeredDate &&
                            convertDate(uneq?.registeredDate)}
                        </p>
                      </Grid>
                    </Grid>
                  </Box>
                  <div className={classes.transferButton}>
                    <Button type="submit" onClick={handleOpen}>
                      Transfer
                    </Button>
                  </div>
                  {uneq.isTexaProduct === true &&
                    uneq.isTexaProductActivated === false &&
                    texaDetails?.data?.response !== 1 && (
                      <div className={classes.popupActiveButtons}>
                        <Button
                          type="submit"
                          onClick={activeProduct}
                          className={classes.popupActiveButton}
                        >
                          Activate Now
                        </Button>
                      </div>
                    )}

                  {uneq.isTexaProduct === true &&
                    uneq.isTexaProductActivated === true && (
                      <div className={classes.activeTextMobile}>
                        {' '}
                        <span>
                          <img
                            src={texaActivated}
                            alt="Texa Product Activated"
                          />
                        </span>
                        Equipment Activated
                      </div>
                    )}
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Hidden>
      <div className={classes.RegisterEquipt_Modal}>
        {open && <RequestForTransfer open={open} setOpen={setOpen} eq={uneq} />}
      </div>
    </>
  );
}
