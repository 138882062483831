import { api } from '@Services/config';
import { uploadApi } from '@Services/config/uploadIndex';
import axios from 'axios';
const getInstallerTypes = async () => api.get(`Installer/GetInstallerTypes`);

const getInstallerByZip = async (zipCode) =>
  api.get(`Installer/GetInstallers/${zipCode}`);

const getEndUser = async (email) => api.get(`User/GetEndUser/${email}`);

const getLocationsByZipOrName = async (userId, name, zip) => {
  let url = `Location/GetUserLocations/${userId}?`;
  if (name) {
    url += `name=${name}&`;
  }

  if (zip) {
    url += `zip=${zip}`;
  }

  return api.get(url);
};

const saveLocation = async (payload = {}) => {
  const url = `Location/SaveInstallationLocation`;
  return api.post(url, payload);
};
const updateLocation = async (payload = {}) => {
  const url = `Location/UpdateInstallationLocation`;
  return api.put(url, payload);
};
const registerUserEquipment = async (payload = {}) => {
  const url = `Equipment/RegisterUserEquipment`;
  return api.post(url, payload);
};
const registerUserMultiEquipment = async (payload = {}) => {
  const url = `Equipment/RegisterMultipleEquipment`;
  return api.post(url, payload);
};
const warrantyUpload = async (payload = {}) => {
  const url = `Equipment/UploadCertificates`;
  console.warn(url, 'uploadConfig');
  return uploadApi.post(url, payload);
};
const getCountries = async (createUserFlag) => {
  const url = `Location/GetCountries?isCreateUser=${createUserFlag}`;
  return api.get(url);
};
const getStates = async (countryID, createUserFlag) => {
  const url = `Location/GetStates/${countryID}?isCreateUser=${createUserFlag}`;
  return api.get(url);
};
const getBusinessname = async (userId, businessName) => {
  const url = `Location/CheckBusinessNameExists/${userId}/${businessName}`;
  return api.get(url);
};

const zipCodeValidation = (country, zipcode) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}&region=${country}&sensor=true&key=AIzaSyD5uG03ZWo4-gLMK4FeieKIEW5Do10MRMM`;
  return axios.get(url);
};

const createUser = async (payload = {}) => {
  const url = `User/CreateSolutionUser`;
  return api.post(url, payload);
};

const texaActivate = async (payload = {}) => {
  const url = `Equipment/ActivateProduct`;
  return api.post(url, payload);
};

const installationService = {
  getInstallerTypes,
  getInstallerByZip,
  getLocationsByZipOrName,
  saveLocation,
  updateLocation,
  registerUserEquipment,
  registerUserMultiEquipment,
  getCountries,
  getStates,
  getEndUser,
  zipCodeValidation,
  createUser,
  texaActivate,
  getBusinessname,
  warrantyUpload,
};

export default installationService;
