const warrantyStrings = {
  extendedWarrantyFormValue:
    'Please provide a valid ID and proceed to upload or capture our warranty certificate for %#No',
  // 'Please provide a valid ID and proceed to upload or capture our warranty certificate.',
  extendedWarrantyValidIDNum:
    'Please enter a valid identification number for %#No.',
  // 'Please enter a valid identification number.',
  extendedWarrantyValidImg:
    'Please upload or Capture a warranty certificate for %#No.',
  extendedWarrantyDuplicateIDNum:
    'The warranty certificate ID has already been used. Please enter a unique warranty certificate ID for each piece of equipment for %#No.',
};
export default warrantyStrings;
