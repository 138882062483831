import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import Hidden from '@mui/material//Hidden';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { authStore } from '@Store/authStore';
import classes from './Header.module.scss';
import logoMbl from '../../../assets/Rotary_Logo_Red_mbl.svg';
import logo from '../../../assets/Rotary_Logo_Red_desk.svg';
import profileIcon from '../../../assets/dashboardICons/userProfile.svg';
import downArrow from '../../../assets/downaroow.svg';
import upArrow from '../../../assets/uparrow.svg';
import { getUserSFDetails } from 'utils/getUserAccount';
import MenuBar from '../MenuBar/MenuBar';
import useIdleTimeout from '@Components/UserIdel/UserIdel';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { authService } from '@Services/auth';
// import getUserSFDetails from 'utils/getUserAccount';
// import help from './../../../assets/HelpPDF';
import Document from './../../../assets/HelpPDF/Doc.pdf';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  outline: '0',
  border: '0',
  // boxShadow: 24,
  // p: 1,
};
export default function Header() {
  const { logout } = authStore();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userObj, setUserObj] = useState({});
  const [delAccountFlag, setDelAccountFlag] = useState(false);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    logout();
    authService.logoutApi(getUserSFDetails().email);
    setAnchorElUser(null);
    setTimeout(() => {
      navigate('/user-logoff');
    }, 300);
  };

  const handleIdle = () => {
    setOpenModal(true);
  };

  const [openModal, setOpenModal] = useState(false);
  /**
   * @param onIdle - function to notify user when idle timeout is close
   * @param idleTime - number of seconds to wait before user is logged out
   */
  const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, idleTime: 1800 });

  const stay = () => {
    setOpenModal(false);
    idleTimer.reset();
  };

  const sUserObj = () => {
    setTimeout(() => {
      const userObjx = getUserSFDetails() || {};
      setUserObj(userObjx);
      if (!userObjx.firstName) {
        sUserObj();
      }
    }, 200);
  };

  useEffect(() => {
    let userObjx = getUserSFDetails() || {};
    if (!userObjx.firstName) {
      sUserObj();
    } else {
      setUserObj(userObjx);
    }
  }, []);

  /*
  Poojitha
  Below function is used for closing logout popup automatically when we click on anywhere on screen 
  */
  const handleCloseLogoutPopUp = () => {
    setAnchorElUser(null);
  };
  const CompanyName = (name) => {
    return name?.slice(0, 15) + '...';
  };
  const handleDeleteAccount = () => {
    setDelAccountFlag(true);
  };
  const fullName = `${userObj.firstName} ${userObj.lastName}`;
  const emailID = userObj.email;
  const emailSubject = 'Account Deletion Request';
  const emailBody = ` %0D%0A Hello Customer Support Team,  %0D%0A  %0D%0A Username: ${fullName}  %0D%0A Registered Email: ${userObj.email} `;
  // const emailBody1 = ` Hello Customer Support Team,  '\r\n'  Username: ${fullName}  '\r\n'  Registered Email: ${userObj.email} `;

  return (
    <div>
      <Grid container>
        <Grid className={classes.HeaderSection}>
          <div className={classes.logoContainer}>
            <Hidden only="xs">
              <Link to="/">
                <img src={logo} alt="VSG Logo" className={classes.Desk_logo} />
              </Link>
            </Hidden>
            <Hidden smUp>
              <Link to="/">
                <img
                  src={logoMbl}
                  alt="VSG Logo"
                  className={classes.Mbl_logo}
                />
              </Link>
            </Hidden>
            <Hidden mdDown>
              <h2 className={classes.rotaryPassport}> Product Registration </h2>
            </Hidden>
          </div>
          <Hidden mdDown>
            <div className={classes.ProfileContainer}>
              <div className={classes.userProfile} style={{ display: 'flex' }}>
                <div
                  className={
                    !userObj.distributorName && !userObj.installerName
                      ? classes.endUserStyles
                      : classes.companyNameContainer
                  }
                >
                  <div className={classes.profileName}>
                    {userObj?.firstName}
                  </div>
                  {userObj.distributorName ? (
                    <div className={classes.companyNameStyles}>
                      <p className={classes.hoverText}>
                        {CompanyName(userObj.distributorName)}
                        <span className={classes.tooltip}>
                          {userObj.distributorName}
                        </span>
                      </p>
                    </div>
                  ) : userObj.installerName ? (
                    <div className={classes.companyNameStyles}>
                      <p className={classes.hoverText}>
                        {CompanyName(userObj.installerName)}
                        <span className={classes.tooltip}>
                          {userObj.installerName}
                        </span>
                      </p>
                    </div>
                  ) : null}
                </div>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <img src={profileIcon} alt="userProfile" />
                  {Boolean(anchorElUser) === true ? (
                    <img
                      src={upArrow}
                      alt="upArrow"
                      style={{ paddingRight: '10px' }}
                    />
                  ) : (
                    <img
                      src={downArrow}
                      alt="DownArrow"
                      style={{ paddingRight: '10px' }}
                    />
                  )}
                </IconButton>
                <div
                  sx={{
                    '& .MuiPaper-root': {
                      top: '16px',
                    },
                  }}
                >
                  <Menu
                    // sx={{ mt: '45px', width: '130px' }}
                    sx={{
                      mt: '45px',
                      '& .MuiList-root': {
                        width: '169px',
                        // height: '104px',
                        background: '#FFFFFF',
                        border: '1px solid #C8D6E4',
                        boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.3)',
                        borderRadius: '4px',
                      },
                    }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseLogoutPopUp}
                  >
                    {' '}
                    {/* <MenuItem>Help</MenuItem> */}
                    {/* <MenuItem onClick={handleCloseUserMenu}>Logout</MenuItem> */}
                    <MenuItem onClick={handleDeleteAccount}>
                      Delete Account
                    </MenuItem>
                    <a href={Document} target="_self" rel="noreferrer">
                      <MenuItem>Help</MenuItem>
                    </a>
                    <MenuItem onClick={handleCloseUserMenu}>Logout</MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          </Hidden>
          <Hidden mdUp>
            <MenuBar />
          </Hidden>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={stay}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.timeOut}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Session Time out
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please click 'Continue' to keep working or click 'Log off' to end
            your session now
          </Typography>
          <div className={classes.buttonSection}>
            <Button onClick={stay} className={classes.buttonStyle1}>
              Continue
            </Button>
            <Button
              onClick={handleCloseUserMenu}
              className={classes.buttonStyle}
            >
              Log off
            </Button>
          </div>
        </Box>
      </Modal>
      <div>
        <Modal
          open={delAccountFlag}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.ModalStyle}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={classes.modalTitle}
            >
              Request for Delete Account
            </Typography>

            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              className={classes.paraGraph}
            >
              <div style={{ textAlign: 'center' }}>
                <div style={{ marginBottom: '10px' }}>
                  {' '}
                  Please note before deleting your account:
                </div>
                <div style={{ marginBottom: '10px' }}>
                  The account deletion process usually takes 1-2 business days
                  to complete.
                </div>

                <div style={{ marginBottom: '10px' }}>
                  Once your account has been successfully deleted, you will
                  receive a confirmation email from our Customer Support Team.{' '}
                </div>

                <div style={{ marginBottom: '10px' }}>
                  Please be aware that deleting your account will result in the
                  permanent loss of all your account-related information,
                  including your profile details and any associated data. This
                  action cannot be undone.
                </div>
              </div>
            </Typography>

            <div className={classes.verifyButton}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setDelAccountFlag(false)}
                  className={classes.buttonStyles}
                >
                  Cancel
                </Button>
              </div>
              <div className={classes.buttonStyles}>
                <a
                  href={`mailto:customerservicegroup@vsgdover.com?subject=${emailSubject}&body=${emailBody}`}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: '200px' }}
                  >
                    Delete Account
                  </Button>
                </a>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
