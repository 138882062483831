import { create } from 'zustand';

const initialState = {
  steps: {
    currentStep: 1,
    completedSteps: 0,
  },
  selectedInstaller: '',
  endUserDetails: {},
  installationType: {},
  locationDetails: {},
  newlocationDetails: {},
  installationDetails: [],
  registrationConfirmation: {},
  selectedEquipmentId: null,
  equipmentTypes: [],
  findLocation: {},
  newLocation: {},
  isNextDisable: true,
  isPrevShow: false,
  isBooleanValue: false,
  isIdNumCheck: false,
  installationDateValue: {},
  selectedRAIZipCode: '',
  selectlocations: 'findLocation',
  selectID: 'singleIDNumber',
};

export const installationStore = create((set, get) => ({
  ...initialState,
  setequipment: (id) => {
    set({ selectedEquipmentId: id });
  },
  setIsBooleanValue: (newValue) =>
    set({
      isBooleanValue: newValue,
    }),
  setIDCheckedValue: (newValue) =>
    set({
      isIdNumCheck: newValue,
    }),
  setSelectlocations: (location) => {
    set({ selectlocations: location });
  },

  setEquipmentTypes: (equipmentTypes) => {
    set((state) => ({
      ...state,
      equipmentTypes,
    }));
  },
  setFindLocation: (findLocation) => {
    set((state) => ({
      ...state,
      findLocation,
    }));
  },
  setNewLocation: (newLocation) => {
    set((state) => ({
      ...state,
      newLocation,
    }));
  },
  setInstallationDate: (installationDateValue) => {
    set((state) => ({
      ...state,
      installationDateValue,
    }));
  },
  setEnteredRAIZipcode: (selectedRAIZipCode) => {
    set((state) => ({
      ...state,
      selectedRAIZipCode,
    }));
  },
  setInstallationDetailsData: (installationDetails) => {
    set((state) => ({
      ...state,
      installationDetails,
    }));
  },

  setEndUserDetails: (data, contineForward) => {
    set((state) => {
      const stateObj = { ...state, endUserDetails: data };
      if (contineForward) {
        stateObj.steps = {
          currentStep: state.steps.currentStep + 1,
          completedSteps: state.steps.currentStep,
        };
      }
      return stateObj;
    });
  },
  setInstallationType: (data, contineForward) => {
    set((state) => {
      const stateObj = { ...state, installationType: data };
      if (contineForward) {
        stateObj.steps = {
          currentStep: state.steps.currentStep + 1,
          completedSteps: state.steps.currentStep,
        };
      }
      return stateObj;
    });
  },
  setInstallationRAIZipCode: (data, contineForward) => {
    set((state) => {
      const stateObj = { ...state, selectedRAIZipCode: data };
      if (contineForward) {
        stateObj.steps = {
          currentStep: state.steps.currentStep + 1,
          completedSteps: state.steps.currentStep,
        };
      }
      return stateObj;
    });
  },
  setLocationDetails: (data, contineForward) => {
    set((state) => {
      const stateObj = { ...state, locationDetails: data };
      if (contineForward) {
        stateObj.steps = {
          currentStep: state.steps.currentStep + 1,
          completedSteps: state.steps.currentStep,
        };
      }
      return stateObj;
    });
  },
  setNewLocationDetails: (data, contineForward) => {
    set((state) => {
      const stateObj = { ...state, newlocationDetails: data };
      if (contineForward) {
        stateObj.steps = {
          currentStep: state.steps.currentStep + 1,
          completedSteps: state.steps.currentStep,
        };
      }
      return stateObj;
    });
  },
  setInstallationDateDetails: (data, contineForward) => {
    set((state) => {
      const stateObj = { ...state, installationDateValue: data };
      if (contineForward) {
        stateObj.steps = {
          currentStep: state.steps.currentStep + 1,
          completedSteps: state.steps.currentStep,
        };
      }
      return stateObj;
    });
  },
  setInstallationDetails: (data, contineForward) => {
    set((state) => {
      const stateObj = { ...state, installationDetails: data };
      if (contineForward) {
        stateObj.steps = {
          currentStep: state.steps.currentStep + 1,
          completedSteps: state.steps.currentStep,
        };
      }
      return stateObj;
    });
  },
  setNextButton: (value) => {
    set((state) => ({ ...state, isNextDisable: value }));
  },
  setStepNext: () => {
    set((state) => ({
      ...state,
      steps: {
        currentStep: state.steps.currentStep + 1,
        completedSteps: state.steps.currentStep,
      },
      isNextDisable: true,
    }));
  },
  setStepPrev: () => {
    set((state) => ({
      ...state,
      steps: {
        currentStep: state.steps.currentStep - 1,
        completedSteps: state.steps.currentStep - 2,
      },
    }));
  },
  setSteps: (val) => {
    set({
      steps: {
        currentStep: val,
        completedSteps: val - 1,
      },
    });
  },
  setEmptyState: () => {
    set((state) => ({ ...state, ...initialState }));
  },
}));
