import React, { useEffect, useState } from 'react';
import useClickHandlers from '../hooks/useClickHandlers';
import { installationStore } from '@Store/installationStore';
import { useParams } from 'react-router-dom';
import { dashboardQuery } from 'features/dahboard/dashboard.query';
import { instalationQuery } from '../Installation.query';
import classes from './RegConfirmation.module.scss';
import { getUserSFDetails } from 'utils/getUserAccount';
import { spinnerStore } from '@Store/spinnerStore';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { Button } from '@Components/Button';
import { Buffer } from 'buffer';
// global.Buffer = require('buffer').Buffer;
function RegConfirmation() {
  const {
    setStepNext,
    setStepPrev,
    locationDetails,
    installationType,
    selectedEquipmentId,
    equipmentTypes,
    setInstallationDetailsData,
    setNewLocation,
    installationDetails,
    installationDateValue,
    isIdNumCheck,
  } = installationStore();
  const { id } = useParams();
  const ids =
    localStorage.getItem('CheckedValues') ||
    localStorage.getItem('CheckedValue');
  const email = getUserSFDetails().email;
  // const { data } = dashboardQuery.useProduct(email, ids, true);
  const { data } = dashboardQuery.useMultiProducts(email, ids, true);
  const { setSpinner, isActive } = spinnerStore();
  console.warn(
    installationDetails,
    isIdNumCheck,
    'setInstallationDetailsData786',
  );
  const {
    mutate: warrantyMutate,
    data: warrantyData,
    isLoading: iswarrantyloadingdata,
    status: warrantyStatus,
  } = instalationQuery.useMutationMultiUploadEq();
  if (warrantyStatus) {
    console.warn(warrantyData?.data?.response, warrantyStatus, 'uploadedData');
  }
  const [uploadedApiData, setUploadedApiData] = useState([]);
  useEffect(() => {
    if (warrantyStatus === 'success') {
      setUploadedApiData(warrantyData?.data?.response);
      setTimeout(() => {
        registerBtnAction();
      }, 1000);
    }
  }, [warrantyStatus, warrantyData]);

  const {
    mutate,
    data: productdata,
    isLoading: isloadingdata,
    status,
    error,
  } = instalationQuery.useMutationMultiRegisterEq();

  const eq = equipmentTypes?.find(
    (ele) => String(ele.id) === String(selectedEquipmentId),
  );
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () =>
      window.removeEventListener('dom.disable_beforeunload', unloadCallback);
  }, []);
  useEffect(() => {
    if (status === 'success') {
      document.querySelector('#cancelRegister').style.display = 'none';
      setStepNext();
    }
  }, [status]);
  const endUserDetails = installationStore((state) => state.endUserDetails);
  let user = getUserSFDetails();
  if (endUserDetails?.id) {
    user = endUserDetails;
  }

  const checkingData = () => {
    if (installationDetails) {
      const existingDatachecking = installationDetails.filter((item) => {
        return item.hasextendedWarranty ?? false;
      });
      return existingDatachecking.length > 0;
    }
    return false;
  };
  const warrantyCheck = checkingData();
  console.warn(installationDateValue, 'setInstallationDateDetails');
  // const installationDateValue = localStorage.getItem('InstalDateValue');
  const installationDate = installationDateValue.split('T')[0];
  let [payload, setPayload] = useState([]);

  const generateImagesFormData = () => {
    let imagesData = [];
    installationDetails.forEach((data) => {
      const dataImage = data.image;
      if (dataImage) {
        const warrantyImageDetails = {
          certificateImage: '',
          name: dataImage.name,
          type: dataImage.type ?? '',
          uri: dataImage.uri,
        };
        const warrantyData = {
          email: user.email ?? '',
          equipmentId: data.equipmentId,
          equipmentNumber: data.equipmentNumber,
          identificationNumber: data.identificationNum ?? '',
          // certificateImage: dataImage.uri,
          name: dataImage.name,
          type: dataImage.type ?? '',
          size: dataImage.size ?? '',
        };
        const newData = {
          warrantyDetails: warrantyData,
          imageDetails: warrantyImageDetails,
        };
        imagesData.push(newData);
        console.warn('Image Data Source', imagesData);
      }
    });
    const detailsJson = [];
    const warrantyImageData = [];
    imagesData.forEach((item) => {
      detailsJson.push(item.warrantyDetails);
      warrantyImageData.push(item.imageDetails);
    });
    console.warn(imagesData, '------ imagesData ------');
    console.warn(detailsJson, '------- detailsJson -------');
    console.warn(warrantyImageData, '------- warrantyImageData ---');

    const imageDataJson = JSON.stringify(detailsJson);
    console.warn(imageDataJson, 'imageDataJson');
    const jsonBase64 = Buffer.from(imageDataJson).toString('base64');
    console.warn(jsonBase64, 'jsonBase64');
    const base64ToBlob = (base64, mime) => {
      const byteChars = atob(base64);
      const byteNumbers = new Array(byteChars.length);
      for (let i = 0; i < byteChars.length; i++) {
        byteNumbers[i] = byteChars.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mime });
    };
    const blob = base64ToBlob(jsonBase64, 'application/json');
    console.warn(blob, 'blob');
    const formData = new FormData();
    // formData.append('formfiles', {
    //   uri: `data:application/json;base64,${jsonBase64}`,
    //   type: 'application/json',
    //   name: 'data.json',
    // });
    formData.append('formfiles', blob, 'data.json');
    warrantyImageData.forEach((image) => {
      // const imageSplit = image.uri.split(',');
      const imageBlob = base64ToBlob(image.uri, image.type);
      formData.append('formfiles', imageBlob, image.name);
      // formData.append('formfiles', {
      //   // uri: `data:${image.type};base64,${image.certificateImage}`,
      //   uri: image.uri,
      //   type: image.type,
      //   name: image.name,
      // });
    });
    console.warn(formData, 'formData123');
    warrantyMutate(formData);
    return formData;
  };
  let warrantySerialNum = [];
  if (installationDetails.length >= 1) {
    installationDetails.forEach((data) => {
      if (data.hasextendedWarranty) {
        warrantySerialNum = {
          equipmentNumber: data.equipmentNumber,
          identificationNumber: data.identificationNum ?? '',
        };
      }
    });
    console.warn(warrantySerialNum, 'warrantySerialNum');
    // for (let i = 0; i < data.length; i++) {
    //   const equipmentInformation = data[i];
    //   const extendedWarrentyDetails = warrantySerialNum.filter((item) => {
    //     return (
    //       item.equipmentNumber.toLowerCase() ===
    //       equipmentInformation.equipmentNumber.toLowerCase()
    //     );
    //   });
    //   console.warn(extendedWarrentyDetails, 'extendedWarrentyDetails11');
    // }
  }
  function registerBtnAction() {
    // register upload api payload
    let payloadSets = [];
    let hasExtendedWarrentyBool = false;
    let identificationNumber = '';
    let extenedWarrentyImageData = '';
    for (let i = 0; i < data.length; i++) {
      if (warrantyData?.data?.response.length > 0) {
        const equipmentInformation = data[i];
        const extendedWarrentyDetails = warrantyData?.data?.response.filter(
          (item) => {
            return (
              item.equipmentNumber.toLowerCase() ===
              equipmentInformation.equipmentNumber.toLowerCase()
            );
          },
        );
        console.warn(extendedWarrentyDetails, 'extendedWarrentyDetails99');
        if (extendedWarrentyDetails.length > 0) {
          const details = extendedWarrentyDetails[0];
          hasExtendedWarrentyBool = details.isUploaded;
          identificationNumber = details.identificationNumber ?? '';
          extenedWarrentyImageData = details.certificateCode ?? '';
        }
      }
      if (String(selectedEquipmentId) === '1') {
        payload = {
          // ...payload,
          // InstallerId: installationType?.installerId,
          equipmentId: data[i].equipmentId,
          userEmail: user.email,
          distributorId: data[i].distributorId,
          userID: user.id,
          equipmentNumber: data[i].equipmentNumber,
          accountId: locationDetails?.accountId,
          registrationSource: 'WebApp',
          InstallerTypeID: +selectedEquipmentId,
          installerLocationId: locationDetails?.id,
          RegisteredBy: getUserSFDetails()?.id,
          RegisteredByEmail: getUserSFDetails()?.email,
          installedDate: installationDate,
          installerFirstName: installationType?.fullName,
          installerLastName: installationType?.lastName || '',
          InstallerPhone: installationType?.contactNumber,
          installerEmail: installationType?.email,
          isExtendedWarranty: hasExtendedWarrentyBool,
          identificationNumber: identificationNumber,
          certificateCode: extenedWarrentyImageData,
        };
      }
      if (String(selectedEquipmentId) === '2') {
        payload = {
          // ...payload,
          equipmentId: data[i].equipmentId,
          userEmail: user.email,
          distributorId: data[i].distributorId,
          userID: user.id,
          equipmentNumber: data[i].equipmentNumber,
          accountId: locationDetails?.accountId,
          registrationSource: 'WebApp',
          InstallerTypeID: +selectedEquipmentId,
          installerLocationId: locationDetails?.id,
          RegisteredBy: getUserSFDetails()?.id,
          RegisteredByEmail: getUserSFDetails()?.email,
          installedDate: installationDate,
          isExtendedWarranty: hasExtendedWarrentyBool,
          identificationNumber: identificationNumber,
          certificateCode: extenedWarrentyImageData,
          InstallerId: installationType?.installerId,
          installerNumber: installationType?.installerNumber,
          installerName: installationType?.name,
        };
      }
      if (String(selectedEquipmentId) === '3') {
        payload = {
          // ...payload,
          // InstallerId: installationType?.installerId,
          equipmentId: data[i].equipmentId,
          userEmail: user.email,
          distributorId: data[i].distributorId,
          userID: user.id,
          equipmentNumber: data[i].equipmentNumber,
          accountId: locationDetails?.accountId,
          registrationSource: 'WebApp',
          InstallerTypeID: +selectedEquipmentId,
          installerLocationId: locationDetails?.id,
          RegisteredBy: getUserSFDetails()?.id,
          RegisteredByEmail: getUserSFDetails()?.email,
          installedDate: installationDate,
          isExtendedWarranty: hasExtendedWarrentyBool,
          identificationNumber: identificationNumber,
          certificateCode: extenedWarrentyImageData,
          InstallerPhone: installationType?.installerNumber,
          installerEmail: installationType?.installerEmail,
          companyName: installationType?.companyName,
        };
      }
      // setPayload((payload) => [...payload, payload]);
      payloadSets.push(payload);
    }
    mutate(payloadSets);
  }
  const handleNextClick = () => {
    if (installationDetails) {
      generateImagesFormData();
    } else {
      registerBtnAction();
    }
    // Utility function to convert Base64 to Blob
    // if (imagesData.length < 1) {
    //   console.warn('--------- no data ----------');
    //   return null;
    // }
  };
  const handlePrevClick = () => setStepPrev();

  const genrateStringByEqId = (idx) => {
    idx = String(idx);
    let string = '';
    if (idx === '1') {
      const { fullName, lastName } = installationType;
      string = fullName;
      string += lastName ? ', ' + lastName : '';
    }
    if (idx === '2') {
      const { name } = installationType;
      string = name;
    }

    if (idx === '3') {
      const { companyName } = installationType;
      string = companyName;
    }

    return string;
  };
  const getAddress = () => {
    const { address1, address2, city, state, country, zip } = locationDetails;
    return [address1, address2, city, state, country, zip]
      .filter((ele) => !!ele)
      .join(', ');
  };
  const Installer = genrateStringByEqId(selectedEquipmentId);
  const addressStr = getAddress();
  const isLoading = status === 'loading';
  const warrantyCheckData = warrantyCheck ? true : false;
  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
  }, [isLoading]);
  useClickHandlers(handleNextClick, handlePrevClick);
  return (
    <div className={classes.regConfirmationPage}>
      <div className={classes.regConfirmationCont}>
        <div className={classes.equipDetails}>
          <h2>Equipment Details</h2>
          {/* <div className={classes.equipDetailsList}>
            <p className={classes.equipSubHead}>Product Name</p>
            <p className={classes.equipSubHeadDetail}>{data?.productName}</p>
          </div>
          <div className={classes.SerailsNumber}>
            <div className={classes.equipDetailsList}>
              <p className={classes.equipSubHead}>Serial Number</p>
              <p className={classes.equipSubHeadDetail}>
                {data?.equipmentNumber}
              </p>
            </div>
            <div className={classes.equipDetailsList}>
              <p className={classes.equipSubHead}>Product Code</p>
              <p className={classes.equipSubHeadDetail}>{data?.productCode}</p>
            </div>
          </div> */}
          <div className={classes.equipDetailsList}>
            <p className={classes.equipSubHead}>Installer Type</p>
            <p className={classes.equipSubHeadDetail}>{eq?.installerType}</p>
          </div>
          <div className={classes.equipDetailsList}>
            <p className={classes.equipSubHead}>Installer</p>
            <p className={classes.equipSubHeadDetail}>{Installer}</p>
          </div>
          {/* <div className={classes.equipDetailsList}>
            <p className={classes.equipSubHead}>Extended Warranty</p>
            <p className={classes.equipSubHeadDetail}>
              {isIdNumCheck ? 'Yes' : 'No'}
            </p>
          </div> */}
          <div className={classes.equipDetailsList}>
            <p className={classes.equipSubHead}>Installation Date</p>
            <p className={classes.equipSubHeadDetail}>{installationDate}</p>
          </div>
        </div>
        <div className={classes.locationDetails}>
          <h2>Location & Contact Details</h2>
          <div className={classes.locationDetailList}>
            <p className={classes.equipSubHead}>Business Name</p>
            <p className={classes.equipSubHeadDetail}>
              {locationDetails?.name}
            </p>
          </div>
          <div className={classes.locationDetailList}>
            <p className={classes.equipSubHead}>Address of the Equipment</p>
            <p className={classes.equipSubHeadDetail}>{addressStr}</p>
          </div>
          <div className={classes.locationDetailList}>
            <p className={classes.equipSubHead}>Email</p>
            <p className={classes.equipSubHeadDetail}>{user.email || 'N/A'}</p>
          </div>
          <div className={classes.locationDetailList}>
            <p className={classes.equipSubHead}>Phone</p>
            <p className={classes.equipSubHeadDetail}>
              {locationDetails?.phoneNumber || 'N/A'}
            </p>
          </div>
        </div>
      </div>
      {error?.response?.data?.message === 'Product Already Registered!!' && (
        <div>
          <Modal
            open={
              error?.response?.data?.message ===
                'Product Already Registered!!' &&
              error?.response?.data?.statusCode === 400
            }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.ModalStyle}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className={classes.modalTitle}
              >
                {error?.response?.data?.message}
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
                className={classes.paraGraph}
              >
                <div style={{ textAlign: 'center' }}>
                  <span style={{ fontWeight: '600' }}>
                    {error?.response?.data?.message}
                  </span>
                  , please click on back button to Register New Product
                </div>
              </Typography>

              <div className={classes.verifyButton}>
                <Link to="/Dashboard">
                  <Button variant="contained" color="primary">
                    Back
                  </Button>
                </Link>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
}
export default RegConfirmation;
