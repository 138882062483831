const commonLimit = {
  multiRegistrationLimit: 10,
  serialNumberMaxTriesForEmail: 2,
  installationDateBackDateinDays: 30,
  filterOptionsBackDateinDays: 60,
  fullNameMaxLength: 40,
  emailMaxLength: 40,
  addressLength: 150,
  zipMaxLength: 7,
  passwordMinLength: 8,
  passwordMaxLength: 20,
  phoneNumberLength: 14,
  serialNumberLength: 20,
  extendedWarrentyIdentificationNumberLength: 20,
};

export default commonLimit;
