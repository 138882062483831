import { createAxiosInstance } from './api';
import getUserAccount from 'utils/getUserAccount';
import envStrings from 'common/EnvStrings';

export const config = {
  API_URL: process.env.REACT_APP_API_BASE_URL || envStrings.API_URL,
  //   'https://test-vsgapi.vsgdover.com/api/',
  // // 'https://boomi-us-dev.dovercorporation.com/vsg-product-registration/api/',
  // // 'https://boomi-us-test.dovercorporation.com/vsg-product-registration/api/',
  // // 'https://boomi-us.dovercorporation.com/vsg-product-registration/api/',
};
// export const uploadConfig = {
//   headers: {
//     'content-type': 'multipart/form-data',
//   },
// };

const { api, setApiHeader } = createAxiosInstance(config);

api.interceptors.request.use((conf) => {
  const userAccount = getUserAccount();
  const accessToken = userAccount?.idToken;
  if (accessToken && conf.headers) {
    conf.headers.Authorization = `Bearer ${accessToken}`;
    // conf.headers.XAPIVersion = 2.0;
  }
  return conf;
});

export { api, setApiHeader };
