/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material//Hidden';
import classNames from 'classnames/bind';
import logoMbl from '../../assets/Rotary_Logo_Red_mbl.svg';
import logo from '../../assets/Rotary_Logo_Red_desk.svg';
import BackgroundSlider from './BackgroundSlider';
import { FooterSection } from '../../common/components/Footer';
import classes from './authentication.template.module.scss';

function AuthenticationTemplate({ children, templateFor = 'deafult' }) {
  const templateName = `${templateFor.toLocaleLowerCase()}Page`;
  return (
    <div
      className={classNames([
        classes.container,
        classes[`${templateName}Container`],
      ])}
    >
      {/* <div className={classes.HeaderContainer}>
        <div item className={classes.HeaderSection}> */}
      {/* <Hidden only="xs">
            <div className={classes.deskLogImg}>
              <Link to="/">
                <img src={logo} alt="VSG Logo" className={classes.Desk_logo} />
              </Link>
            </div>
            <div className={classes.RightImgTop}>
              <img src={RightTopIcon} alt="Right Top Vector" />
            </div>
          </Hidden> */}
      {/* <Hidden mdUp>
            <Link to="/">
              <img src={logoMbl} alt="VSG Logo" className={classes.Mbl_logo} />
            </Link>
          </Hidden> */}
      {/* </div>
      </div> */}

      {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item sm={12} md={12} className={classes.leftContainer}>
          <Hidden mdDown> */}
      {/* <div className={classes.rotaryLiftImgSection}> */}
      {/* <BackgroundSlider /> */}
      {/* <div className={classes.sliderContent}>
                <div className={classes.textCont}> */}
      {/* <div className={classes.deskLogImg}>
                    <Link to="/">
                      <img
                        src={logo}
                        alt="VSG Logo"
                        className={classes.Desk_logo}
                      />
                    </Link>
                  </div> */}
      {/* <h2>
                    We{' '}
                    <span className={classes.redHighlight}>
                      manufacture, develop & sell
                    </span>{' '}
                    the world’s most trusted products.
                  </h2> */}
      {/* <h2>Serving the shop since 1925.</h2> */}
      {/* <div className={classes.dots}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div> */}
      {/* </div>
              </div> */}
      {/* <div className={classes.landingFooter}>
                <div className={classes.footerCont}> */}
      {/* <p>
                    © 1999 - 2023. Rotary Lift | Powered by{' '}
                    <a
                      href="https://vsgdover.com/?__hstc=59739570.a56eacb93a3ec1db9ce792bec55e98a2.1679641100984.1680678415939.1680709646415.6&__hssc=59739570.1.1680709646415&__hsfp=32358173"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Vehicle Service Group℠
                    </a>{' '}
                    | All Rights Reserved. Unless otherwise indicated, Vehicle
                    Service Group and all other trademarks are property of Dover
                    Corporation and its affiliates.
                  </p> */}
      {/* <p className={classes.footerLinks}>
            Privacy Policy | Cookie Usage | Terms of Service | Do Not Sell
          </p> */}
      {/* <p className={classes.footerLinks}>
                    <a
                      href="https://vsgdover.com/privacy-policy/?__hstc=59739570.e91bdf985d82e8a9f6d88db2be16a7d1.1680683821277.1680683821277.1680683821277.1&__hssc=59739570.1.1680683821278&__hsfp=32358173"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>{' '}
                    |{' '}
                    <a
                      href="https://vsgdover.com/cookie/?__hstc=59739570.e91bdf985d82e8a9f6d88db2be16a7d1.1680683821277.1680683821277.1680683821277.1&__hssc=59739570.2.1680683821278&__hsfp=32358173"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Cookie Usage
                    </a>{' '}
                    |{' '}
                    <a
                      href="https://vsgdover.com/terms-of-service/?__hstc=59739570.e91bdf985d82e8a9f6d88db2be16a7d1.1680683821277.1680683821277.1680683821277.1&__hssc=59739570.3.1680683821278&__hsfp=32358173"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Services
                    </a>{' '}
                    |{' '}
                    <a
                      href="http://vsgdover.com/do-not-sell"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Do Not Sell
                    </a>
                  </p> */}
      {/* </div>
              </div>
            </div> */}
      {/* </Hidden>
        </Grid> */}

      <Grid item xs={12} sm={12} md={12}>
        <div
          className={classNames(classes.rightContainer, classes[templateName])}
        >
          {children}
        </div>
      </Grid>
      {/* <div className={classes.bottomLeftContainer}>
          <img src={vsgLeftIconBelowDesk} alt="bottomLeftDesign" />
        </div> */}
      {/* </Grid> */}
      {/* <Hidden mdUp>
        <FooterSection />
      </Hidden> */}
    </div>
  );
}

export default React.memo(AuthenticationTemplate);
