/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
const useQRCodeScan = ({
  qrcodeMountNodeID = '',
  closeAfterScan = true,
  getQrBoxDimension,
}) => {
  const [decodedQRData, setDecoderQrData] = useState({
    isScanning: false,
    isScanSuccess: false,
    isScanFailure: false,
    data: null,
    error: '',
  });
  const html5QrCodeScannerRef = React.useRef(null);

  // unmount logic
  useEffect(() => {
    return () => {
      // if (html5QrCodeScannerRef?.current) {
      //   html5QrCodeScannerRef?.current
      //     ?.stop()
      //     ?.then((ignore) => {
      //       // QR Code scanning is stopped
      //
      //     })
      //     ?.catch((err) => {
      //       // Stop failed, handle it.
      //
      //     });
      // }
    };
  }, []);

  const stopQrCode = () => {
    if (html5QrCodeScannerRef.current) {
      html5QrCodeScannerRef.current
        ?.stop()
        ?.then((ignore) => {
          // QR Code scanning is stopped
        })
        ?.catch((err) => {
          // Stop failed, handle it.
        });
    }
  };

  const startQrCode = () => {
    try {
      // eslint-disable-next-line no-undef
      setDecoderQrData({
        ...decodedQRData,
        isScanning: true,
        data: null,
      });
      // eslint-disable-next-line
      const html5qrCodeScanner = new Html5Qrcode(qrcodeMountNodeID);

      html5QrCodeScannerRef.current = html5qrCodeScanner;

      let qrbox = 250;
      if (getQrBoxDimension) {
        qrbox = getQrBoxDimension();
      }

      html5qrCodeScanner
        .start(
          // { deviceId: { exact: cameraId } },
          { facingMode: 'environment' },

          { fps: 100, qrbox, aspectRatio: 1 },
          (qrCodeMessage) => {
            // do something when code is read

            setDecoderQrData({
              ...decodedQRData,
              isScanSuccess: true,
              isScanning: false,
              data: qrCodeMessage,
              error: '',
            });

            if (closeAfterScan) {
              html5qrCodeScanner
                .stop()
                .then((ignore) => {
                  // QR Code scanning is stopped.
                  // setIsOpenCamera(false);
                })
                .catch((err) => {
                  // Stop failed, handle it.
                });
            }
          },
          (errorMessage) => {},
        )
        .catch((err) => {
          setDecoderQrData({
            ...decodedQRData,
            isScanSuccess: false,
            isScanning: false,
            isScanFailure: true,
            data: null,
            error: err || 'QR Code parsing failed',
          });
        });
    } catch (e) {
      setDecoderQrData({
        ...decodedQRData,
        isScanSuccess: false,
        isScanning: false,
        isScanFailure: true,
        data: null,
        error: e || 'QR Code parsing failed',
      });
    }
  };

  const uploadQrimage = (imageFile) => {
    try {
      if (!html5QrCodeScannerRef.current) {
        // eslint-disable-next-line
        const html5qrCodeScanner = new Html5Qrcode(qrcodeMountNodeID);

        html5QrCodeScannerRef.current = html5qrCodeScanner;
      }
      html5QrCodeScannerRef.current
        .scanFile(imageFile, true)
        .then((decodedText) => {
          // success, use decodedText

          setDecoderQrData({
            ...decodedQRData,
            isScanSuccess: true,
            isScanning: false,
            data: decodedText,
            error: '',
          });
          console.warn(decodedQRData, 'decodedQRDATA');
        })
        .catch((err) => {
          toast.error(
            'Uploaded QR code is not formated correctly. Please close and upload the correct Format QR code.',
            {
              position: toast.POSITION.TOP_CENTER,
            },
          );
          console.warn(err);
          // alert(err);
          // failure, handle it.
        });
    } catch (e) {}
  };

  return {
    startQrCode,
    decodedQRData,
    stopQrCode,
    uploadQrimage,
  };
};

export default useQRCodeScan;
