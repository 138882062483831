import React from 'react';
import shallow from 'zustand/shallow';
import { installationStore } from '@Store/installationStore';
import InstallationContainer from './InstallationContainer';
import LocationDetails from './Location';
import RegConfirmation from './RegConfirmation';
import RegSuccess from './RegSuccess';
import { getUserSFDetails, isEndUser } from 'utils/getUserAccount';
import EndUserSelection from './InstallationType/EndUserSelection/EndUserSelection';
import CsrRegSuccess from './CsrRegSuccess';
import InstallationDetails from './InstallationDetails';
import Dashboard from 'features/dahboard';

function WrappedInstallationMapper() {
  const steps = installationStore((state) => state.steps, shallow);
  const statusId = localStorage.getItem('statusId');

  const { currentStep } = steps || {};
  const user = getUserSFDetails();
  const plusCount = isEndUser(user.userRole) ? 1 : 0;
  return (
    <>
      {user.userRole === 'CSR' && statusId === '1' ? (
        <CsrRegSuccess />
      ) : (
        <>
          {plusCount === 1 && currentStep === 1 && <EndUserSelection />}
          {currentStep === 1 + plusCount && <InstallationContainer />}
          {currentStep === 2 + plusCount && <LocationDetails />}
          {currentStep === 3 + plusCount && <InstallationDetails />}
          {currentStep === 4 + plusCount && <RegConfirmation />}
          {currentStep === 5 + plusCount && <RegSuccess />}
        </>
      )}

      {/* <>
        {plusCount === 1 && currentStep === 1 && <EndUserSelection />}
        {currentStep === 1 + plusCount && <InstallationContainer />}
        {currentStep === 2 + plusCount && <LocationDetails />}
        {currentStep === 3 + plusCount && <InstallationDetails />}
        {currentStep === 4 + plusCount && <RegConfirmation />}
        {currentStep === 5 + plusCount && <RegSuccess />}
      </> */}
    </>
  );
}

export default React.memo(WrappedInstallationMapper);
