import React, { useContext, useEffect } from 'react';
import { RefButton } from '@Components/Button';
import { Link } from 'react-router-dom';
import { installationStore } from '@Store/installationStore';
import ButtonRefsContext from '../hooks/NavigationButtons.context';
import { shallow } from 'zustand/shallow';
import classes from './HandleNav.module.scss';
import { getUserSFDetails, isEndUser } from 'utils/getUserAccount';
import { spinnerStore } from '@Store/spinnerStore';
const navStyles = {
  display: 'flex',
  justifyContent: 'space-between',
};

const HandleNav = () => {
  const { setSpinner, isActive } = spinnerStore();
  const { nextButtonRef, prevButtonRef } = useContext(ButtonRefsContext);
  const { isNextDisable, steps } = installationStore((state) => {
    const { isNextDisable, steps } = state;
    return { isNextDisable, steps };
  }, shallow);
  const { currentStep } = steps || {};
  const user = getUserSFDetails();
  const plusCount = isEndUser(user.userRole) ? 1 : 0;
  const urlParam = true;
  const url = `/Dashboard?${urlParam}`;
  const statusId = localStorage.getItem('statusId');
  const valueFalg = user.userRole === 'CSR' && statusId === '1' ? true : false;
  // eslint-disable-next-line no-restricted-globals
  const isLoading = status === 'loading';
  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
  }, [isLoading]);
  return (
    <div
      style={currentStep > 1 ? navStyles : null}
      className={
        currentStep === 5 + plusCount
          ? classes.handleNavsectionStyles
          : classes.handleNavsection
      }
    >
      {currentStep > 1 && currentStep !== 5 + plusCount && (
        <RefButton ref={prevButtonRef}>BACK</RefButton>
      )}
      {currentStep === 5 + plusCount && (
        <div className={classes.equipLink}>
          <Link to={url}>REGISTER MORE EQUIPMENT</Link>
        </div>
      )}
      {!valueFalg && (
        <RefButton
          ref={nextButtonRef}
          props={{
            disabled: !!isNextDisable,
          }}
        >
          {plusCount === 1 && currentStep === 1 && <>NEXT</>}
          {currentStep === 1 + plusCount && <>NEXT</>}
          {currentStep === 2 + plusCount && <>CONFIRM</>}
          {currentStep === 3 + plusCount && <>NEXT</>}
          {currentStep === 4 + plusCount && <>REGISTER</>}
          {currentStep === 5 + plusCount && (
            <span className={classes.textStyles}>View my equipment</span>
          )}
        </RefButton>
      )}
    </div>
  );
};

export default HandleNav;

// import React, { useContext, useEffect } from 'react';
// import { RefButton } from '@Components/Button';
// import { Link } from 'react-router-dom';
// import { installationStore } from '@Store/installationStore';
// import ButtonRefsContext from '../hooks/NavigationButtons.context';
// import { shallow } from 'zustand/shallow';
// import classes from './HandleNav.module.scss';
// import { getUserSFDetails, isEndUser } from 'utils/getUserAccount';
// import { spinnerStore } from '@Store/spinnerStore';
// const navStyles = {
//   display: 'flex',
//   justifyContent: 'space-between',
// };

// const HandleNav = () => {
//   const { setSpinner, isActive } = spinnerStore();
//   const { nextButtonRef, prevButtonRef } = useContext(ButtonRefsContext);
//   const { isNextDisable, steps } = installationStore((state) => {
//     const { isNextDisable, steps } = state;
//     return { isNextDisable, steps };
//   }, shallow);
//   const { currentStep } = steps || {};
//   const user = getUserSFDetails();
//   const plusCount = isEndUser(user.userRole) ? 1 : 0;
//   const urlParam = true;
//   const url = `/Dashboard?${urlParam}`;
//   const statusId = localStorage.getItem('statusId');
//   const valueFalg = user.userRole === 'CSR' && statusId === '1' ? true : false;
//   // eslint-disable-next-line no-restricted-globals
//   const isLoading = status === 'loading';
//   useEffect(() => {
//     if (isActive !== isLoading) {
//       setSpinner(isLoading);
//     }
//   }, [isLoading]);
//   return (
//     <div
//       style={currentStep > 1 ? navStyles : null}
//       className={
//         currentStep === 4 + plusCount
//           ? classes.handleNavsectionStyles
//           : classes.handleNavsection
//       }
//     >
//       {currentStep > 1 && currentStep !== 4 + plusCount && (
//         <RefButton ref={prevButtonRef}>BACK</RefButton>
//       )}
//       {currentStep === 4 + plusCount && (
//         <div className={classes.equipLink}>
//           <Link to={url}>REGISTER MORE EQUIPMENT</Link>
//         </div>
//       )}
//       {!valueFalg && (
//         <RefButton
//           ref={nextButtonRef}
//           props={{
//             disabled: !!isNextDisable,
//           }}
//         >
//           {plusCount === 1 && currentStep === 1 && <>NEXT</>}
//           {currentStep === 1 + plusCount && <>NEXT</>}
//           {currentStep === 2 + plusCount && <>CONFIRM</>}
//           {currentStep === 3 + plusCount && <>REGISTER</>}
//           {currentStep === 4 + plusCount && (
//             <span className={classes.textStyles}>View my equipment</span>
//           )}
//         </RefButton>
//       )}
//     </div>
//   );
// };

// export default HandleNav;
