import React, { useEffect, useMemo, useState, useRef } from 'react';
import Stack from '@mui/material/Stack';
import classes from './EndUserSelection.module.scss';
import { instalationQuery } from 'features/Installation/Installation.query';
import { MappedInput } from '@Components/Input';
import { Button } from '@Components/Button';
import SearchResults from '@Components/SearchResults';
import { formFeilds, useFormHook } from './EndUserSelection.form.initializer';
import useClickHandlers from 'features/Installation/hooks/useClickHandlers';
import { installationStore } from '@Store/installationStore';
import { getUserSFDetails } from 'utils/getUserAccount';
import CreateOwner from './CreateOwner';
import { dashboardQuery } from 'features/dahboard/dashboard.query';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';

function EndUserSelection() {
  const userEmail = getUserSFDetails().email || '';
  const [payload, setPayload] = useState(userEmail);
  const [open, setOpen] = useState(false);
  const [searchResultsId, setSearchResultsId] = useState('');
  const [valueCheck, setValueCheck] = useState(false);
  const { setStepPrev, setNextButton, setEndUserDetails } = installationStore();
  const endUserDetails = installationStore((state) => state.endUserDetails);
  // const { id } = useParams();
  // const { data: serialIDData } = dashboardQuery.useProduct(userEmail, id, true);
  const ids =
    localStorage.getItem('CheckedValues') ||
    localStorage.getItem('CheckedValue');
  // const { data } = dashboardQuery.useProduct(email, ids, true);
  const { data: serialIDData } = dashboardQuery.useMultiProducts(
    userEmail,
    ids,
    true,
  );
  const [fetch, { data, isSuccess, status }] =
    instalationQuery.useGetUserDetails(payload);
  const [valueFlag, setValueFlag] = useState(false);
  const [endUserStatus, setEndUserStatus] = useState('');

  const onSubmit = (values) => {
    setPayload(values?.endUserEmail);
    setTimeout(() => {
      fetch();
    }, 100);
    setValueFlag(true);
    setValueCheck(true);
    setNextButton(true);
    setSearchResultsId('');
  };
  localStorage.setItem('EndUserDetail', JSON.stringify(data));
  const handleData = () => {
    setPayload(values.endUserEmail);
    setTimeout(() => {
      fetch();
    }, 100);
    setValueFlag(true);
    setValueCheck(true);
    setNextButton(true);
    setSearchResultsId('');
  };
  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    setValueCheck(true);
    if (endUserDetails.email) {
      setPayload(endUserDetails.email);
      setSearchResultsId(endUserDetails.id);
      setTimeout(() => {
        fetch();
        setNextButton(false);
      }, 100);
    }
  }, []);
  const initialFormValues = {
    endUserEmail: endUserDetails.email || '',
  };

  const form = useFormHook(onSubmit, initialFormValues);
  const { touched, isValid, values } = form;
  const isTouched = Object.keys(touched).length !== 0 || !!endUserDetails?.id;
  const handleChangeResults = ({ target }) => {
    setSearchResultsId(target.value);
    setNextButton(false);
  };
  const handlePrevClick = () => {
    setStepPrev();
  };
  useEffect(() => {
    setValueFlag(false);
  }, [values.endUserEmail]);

  const handleNextClick = () => {
    const item = data.find((ele) => ele.id === +searchResultsId);
    setEndUserDetails({ ...item }, true);
  };
  useEffect(() => {
    if (data && data.length > 0 && endUserStatus === 'success') {
      setSearchResultsId(data[0]?.id);
    }
  }, [data]);
  useEffect(() => {
    if (
      data &&
      data.length > 0 &&
      endUserStatus === 'success' &&
      searchResultsId
    ) {
      setTimeout(() => {
        handleNextClick();
      }, 1000);
    }
  }, [data && endUserStatus === 'success' && searchResultsId]);
  useEffect(() => {
    if (
      (data !== null || data === null) &&
      values.endUserEmail === '' &&
      status === 'success'
    ) {
      setValueCheck(false);
      setSearchResultsId('');
    }
  }, [values, status, data]);
  useEffect(() => {
    setNextButton(true);
  }, [!(isTouched && isValid), values.endUserEmail !== '']);
  useClickHandlers(handleNextClick, handlePrevClick);
  const props = useMemo(() => ({
    items: data,
    title: 'Search results',
    handleChange: handleChangeResults,
    keys: ['endUserEmail'],
    selectRowId:
      endUserStatus === 'success' && data
        ? data[0] && data[0]?.id
        : searchResultsId,
  }));

  const findOwnerButtonRef = useRef(null);
  const flag = /^[a-zA-Z0-9._-]+@[a-zA-Z]+\.[a-zA-Z]{2,5}$/.test(
    values.endUserEmail,
  )
    ? true
    : false;
  useEffect(() => {
    if (flag === true && endUserStatus === 'success') {
      handleData();
    }
  }, [flag === true && endUserStatus === 'success']);

  useEffect(() => {
    if (endUserStatus === 'success' && data) {
      setNextButton(false);
    }
  }, [data]);
  return (
    <div>
      <div className={classes.LocationDetailCont}>
        <div className={classes.FindLocationCont}>
          <div className={classes.locationFind}>
            <form onSubmit={form.handleSubmit}>
              <div className={classes.findLocationFormCont}>
                <h2>Select Equipment owner </h2>
                <MappedInput fields={formFeilds} form={form} />
                <div className={classes.findLocationButton}>
                  <Button
                    className={classes.findBtn}
                    type="submit"
                    disabled={!flag}
                    onClick={onSubmit}
                    ref={findOwnerButtonRef}
                  >
                    Find Owner
                  </Button>
                </div>
              </div>

              <div className={classes.locationResults}>
                {data &&
                  isSuccess &&
                  values.endUserEmail !== '' &&
                  valueCheck === true && <SearchResults {...props} />}
              </div>
              {!data &&
                valueFlag &&
                isSuccess &&
                values.endUserEmail !== '' &&
                valueCheck === true &&
                !form.errors.endUserEmail && (
                  <Stack
                    sx={{
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    spacing={2}
                  >
                    <div className={classes.resultsNotFound}>
                      <div className={classes.divider}></div>
                      <h2>Search Results</h2>
                      <p>
                        Currently, there is no user associated with the provided
                        email address. Please create account for this email
                        <span> “{values.endUserEmail}”.</span>
                      </p>
                      <div className={classes.createAccount}>
                        <Button onClick={handleOpen}>Create User</Button>
                        {open && (
                          <CreateOwner
                            open={open}
                            setOpen={setOpen}
                            values={form}
                            setEndUserStatus={setEndUserStatus}
                          />
                        )}
                      </div>
                    </div>
                  </Stack>
                )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EndUserSelection;

// import React, { useEffect, useMemo, useState, useRef } from 'react';
// import Stack from '@mui/material/Stack';
// import classes from './EndUserSelection.module.scss';
// import { instalationQuery } from 'features/Installation/Installation.query';
// import { MappedInput } from '@Components/Input';
// import { Button } from '@Components/Button';
// import SearchResults from '@Components/SearchResults';
// import { formFeilds, useFormHook } from './EndUserSelection.form.initializer';
// import useClickHandlers from 'features/Installation/hooks/useClickHandlers';
// import { installationStore } from '@Store/installationStore';
// import { getUserSFDetails } from 'utils/getUserAccount';
// import CreateOwner from './CreateOwner';
// import { dashboardQuery } from 'features/dahboard/dashboard.query';
// import { useParams } from 'react-router-dom';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import { Link } from 'react-router-dom';

// function EndUserSelection() {
//   const userEmail = getUserSFDetails().email || '';
//   const [payload, setPayload] = useState(userEmail);
//   const [open, setOpen] = useState(false);
//   const [searchResultsId, setSearchResultsId] = useState('');
//   const [valueCheck, setValueCheck] = useState(false);
//   const { setStepPrev, setNextButton, setEndUserDetails } = installationStore();
//   const endUserDetails = installationStore((state) => state.endUserDetails);
//   const { id } = useParams();
//   const { data: serialIDData } = dashboardQuery.useProduct(userEmail, id, true);
//   const [fetch, { data, isSuccess, status }] =
//     instalationQuery.useGetUserDetails(payload);
//   const [valueFlag, setValueFlag] = useState(false);
//   const [endUserStatus, setEndUserStatus] = useState('');

//   const onSubmit = (values) => {
//     setPayload(values?.endUserEmail);
//     setTimeout(() => {
//       fetch();
//     }, 100);
//     setValueFlag(true);
//     setValueCheck(true);
//     setNextButton(true);
//     setSearchResultsId('');
//   };
//   localStorage.setItem('EndUserDetail', JSON.stringify(data));
//   const handleData = () => {
//     setPayload(values.endUserEmail);
//     setTimeout(() => {
//       fetch();
//     }, 100);
//     setValueFlag(true);
//     setValueCheck(true);
//     setNextButton(true);
//     setSearchResultsId('');
//   };
//   const handleOpen = () => {
//     setOpen(true);
//   };
//   useEffect(() => {
//     setValueCheck(true);
//     if (endUserDetails.email) {
//       setPayload(endUserDetails.email);
//       setSearchResultsId(endUserDetails.id);
//       setTimeout(() => {
//         fetch();
//         setNextButton(false);
//       }, 100);
//     }
//   }, []);
//   const initialFormValues = {
//     endUserEmail: endUserDetails.email || '',
//   };

//   const form = useFormHook(onSubmit, initialFormValues);
//   const { touched, isValid, values } = form;
//   const isTouched = Object.keys(touched).length !== 0 || !!endUserDetails?.id;
//   const handleChangeResults = ({ target }) => {
//     setSearchResultsId(target.value);
//     setNextButton(false);
//   };
//   const handlePrevClick = () => {
//     setStepPrev();
//   };
//   useEffect(() => {
//     setValueFlag(false);
//   }, [values.endUserEmail]);

//   const handleNextClick = () => {
//     const item = data.find((ele) => ele.id === +searchResultsId);
//     setEndUserDetails({ ...item }, true);
//   };
//   useEffect(() => {
//     if (data && data.length > 0 && endUserStatus === 'success') {
//       setSearchResultsId(data[0]?.id);
//     }
//   }, [data]);
//   useEffect(() => {
//     if (
//       data &&
//       data.length > 0 &&
//       endUserStatus === 'success' &&
//       searchResultsId
//     ) {
//       setTimeout(() => {
//         handleNextClick();
//       }, 1000);
//     }
//   }, [data && endUserStatus === 'success' && searchResultsId]);
//   useEffect(() => {
//     if (
//       (data !== null || data === null) &&
//       values.endUserEmail === '' &&
//       status === 'success'
//     ) {
//       setValueCheck(false);
//       setSearchResultsId('');
//     }
//   }, [values, status, data]);
//   useEffect(() => {
//     setNextButton(true);
//   }, [!(isTouched && isValid), values.endUserEmail !== '']);
//   useClickHandlers(handleNextClick, handlePrevClick);
//   const props = useMemo(() => ({
//     items: data,
//     title: 'Search results',
//     handleChange: handleChangeResults,
//     keys: ['endUserEmail'],
//     selectRowId:
//       endUserStatus === 'success' && data
//         ? data[0] && data[0]?.id
//         : searchResultsId,
//   }));

//   const findOwnerButtonRef = useRef(null);
//   const flag = /^[a-zA-Z0-9._-]+@[a-zA-Z]+\.[a-zA-Z]{2,5}$/.test(
//     values.endUserEmail,
//   )
//     ? true
//     : false;
//   useEffect(() => {
//     if (flag === true && endUserStatus === 'success') {
//       handleData();
//     }
//   }, [flag === true && endUserStatus === 'success']);

//   useEffect(() => {
//     if (endUserStatus === 'success' && data) {
//       setNextButton(false);
//     }
//   }, [data]);
//   return (
//     <div>
//       {serialIDData?.statusId !== 1 &&
//       serialIDData?.statusMessage === 'Unregistered' ? (
//         <div className={classes.LocationDetailCont}>
//           <div className={classes.FindLocationCont}>
//             <div className={classes.locationFind}>
//               <form onSubmit={form.handleSubmit}>
//                 <div className={classes.findLocationFormCont}>
//                   <h2>Select Equipment owner </h2>
//                   <MappedInput fields={formFeilds} form={form} />
//                   <div className={classes.findLocationButton}>
//                     <Button
//                       className={classes.findBtn}
//                       type="submit"
//                       disabled={!flag}
//                       onClick={onSubmit}
//                       ref={findOwnerButtonRef}
//                     >
//                       Find Owner
//                     </Button>
//                   </div>
//                 </div>

//                 <div className={classes.locationResults}>
//                   {data &&
//                     isSuccess &&
//                     values.endUserEmail !== '' &&
//                     valueCheck === true && <SearchResults {...props} />}
//                 </div>
//                 {!data &&
//                   valueFlag &&
//                   isSuccess &&
//                   values.endUserEmail !== '' &&
//                   valueCheck === true &&
//                   !form.errors.endUserEmail && (
//                     <Stack
//                       sx={{
//                         width: '100%',
//                         alignItems: 'center',
//                         justifyContent: 'center',
//                       }}
//                       spacing={2}
//                     >
//                       <div className={classes.resultsNotFound}>
//                         <div className={classes.divider}></div>
//                         <h2>Search Results</h2>
//                         <p>
//                           Currently, there is no user associated with the
//                           provided email address. Please create account for this
//                           email
//                           <span> “{values.endUserEmail}”.</span>
//                         </p>
//                         <div className={classes.createAccount}>
//                           <Button onClick={handleOpen}>Create User</Button>
//                           {open && (
//                             <CreateOwner
//                               open={open}
//                               setOpen={setOpen}
//                               values={form}
//                               setEndUserStatus={setEndUserStatus}
//                             />
//                           )}
//                         </div>
//                       </div>
//                     </Stack>
//                   )}
//               </form>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div>
//           {' '}
//           <Modal
//             open={
//               (serialIDData?.statusId === 1 &&
//                 serialIDData?.statusMessage ===
//                   'Product Already Registered!!') ||
//               serialIDData === null
//             }
//             aria-labelledby="modal-modal-title"
//             aria-describedby="modal-modal-description"
//           >
//             <Box className={classes.ModalStyle}>
//               {serialIDData === null ? (
//                 <Typography
//                   id="modal-modal-title"
//                   variant="h6"
//                   component="h2"
//                   className={classes.modalTitle}
//                 >
//                   Equipment not found
//                 </Typography>
//               ) : (
//                 <Typography
//                   id="modal-modal-title"
//                   variant="h6"
//                   component="h2"
//                   className={classes.modalTitle}
//                 >
//                   {serialIDData?.statusMessage}
//                 </Typography>
//               )}
//               {serialIDData === null ? (
//                 <Typography
//                   id="modal-modal-description"
//                   sx={{ mt: 2 }}
//                   className={classes.paraGraph}
//                 >
//                   <div style={{ textAlign: 'center' }}>
//                     <span style={{ fontWeight: '600' }}>
//                       Equipment not found
//                     </span>
//                     , please click on back button to Register New Product
//                   </div>
//                 </Typography>
//               ) : (
//                 <Typography
//                   id="modal-modal-description"
//                   sx={{ mt: 2 }}
//                   className={classes.paraGraph}
//                 >
//                   <div style={{ textAlign: 'center' }}>
//                     <span style={{ fontWeight: '600' }}>
//                       Product Already Registered
//                     </span>
//                     , please click on back button to Register New Product
//                   </div>
//                 </Typography>
//               )}
//               <div className={classes.verifyButton}>
//                 <Link to="/Dashboard">
//                   <Button variant="contained" color="primary">
//                     Back
//                   </Button>
//                 </Link>
//               </div>
//             </Box>
//           </Modal>
//         </div>
//       )}
//     </div>
//   );
// }

// export default EndUserSelection;
