import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Button } from '@Components/Button';
import classes from './UnregisterData.module.scss';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material//Hidden';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import texaActivated from './../../../assets/Texa_Activated.svg';
import accordionMoreIcon from './../../../assets/dashboardICons/accordionMore.svg';
import accordionLessIcon from './../../../assets/dashboardICons/accordionLess.svg';
import accordionMoreColorIcon from './../../../assets/dashboardICons/accordionMoreColorIcon.svg';
import Typography from '@mui/material/Typography';
import { RegisterEq } from './../RegisterEq';
import { getUserSFDetails } from 'utils/getUserAccount';
import { instalationQuery } from './../../Installation/Installation.query';
import Modal from '@mui/material/Modal';
import texaIcon from './../../../assets/dashboardICons/texaIcon.svg';
import touchIcon from './../../../assets/touch_screen.png';
import closeIcon from './../../../assets/closeIcon.svg';
import { Link } from 'react-router-dom';
import { dashboardQuery } from 'features/dahboard/dashboard.query';
import { useNavigate } from 'react-router-dom';
const UnregisterData = (
  { equipment: uneq, selectedEquip, selectedEquipment },
  ref,
) => {
  const [checked, setChecked] = useState(false);
  const [openRegister, setOpenRegister] = React.useState(false);
  const [allcheckedstring, setAllCheckedstring] = useState();
  const [isShown, setIsShown] = React.useState(false);
  const [openAccordionId, setOpenAccordionId] = React.useState(null);
  const handleAccordionChange = (itemId) => {
    setOpenAccordionId(itemId === openAccordionId ? null : itemId);
  };
  const [activatedPopup, setactivatedPopup] = React.useState(false);
  const [activatePopup, setActivatePopup] = React.useState(false);

  useImperativeHandle(ref, () => ({
    showView: showView,
  }));
  function showView(recentData) {
    setAllCheckedstring(recentData);
    console.warn(allcheckedstring, recentData, 'searchUnReg786');
  }
  const allChecked = selectedEquip;
  function isSelected(equipID) {
    if (allChecked.length > 0) {
      console.warn(allChecked, 'allChecked');
      if (!checked) {
        setChecked(true);
      }
    } else {
      if (checked) {
        setChecked(false);
      }
    }
    const existingData = allChecked.filter((item) => {
      return item === equipID.equipmentNumber;
    });

    console.warn(existingData, 'handleChangeData786');
    return existingData.length > 0;
  }
  const handleChange = (e) => {
    console.warn(e.target, 'eventtrigger');
    selectedEquipment(e.target.value);
    setChecked(e.target.checked);
  };

  const activeProduct = () => {
    setActivatePopup(true);
  };
  const handleClose = () => {
    setActivatePopup(false);
  };

  const userData = getUserSFDetails();
  const {
    mutate,
    status,
    data: texaDetails,
  } = instalationQuery.useMutationtexaActivate();
  const {
    mutate: singleSelectedProductMutate,
    status: singleProductStatus,
    data: singleSelectedProduct,
    isLoading,
  } = dashboardQuery.useMultiMutationProducts();
  const [singleProductRegister, setSingleProductRegister] = useState('');
  const handleClickUnRegisterEquip = (e) => {
    let dataToRegister = e.target.value;
    setSingleProductRegister(dataToRegister);
    // setSpinner(true);
    // setMultiProductPayload(dataToRegister.toString());
    setTimeout(() => {
      singleSelectedProductMutate(dataToRegister);
    }, 100);
    // localStorage.removeItem('CheckedValue');
    // localStorage.setItem('checkedValuesLength', dataToRegister.length);
    localStorage.setItem('CheckedValues', dataToRegister);
    console.warn(singleSelectedProduct, 'singleSelectedProduct');
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (singleSelectedProduct && singleProductStatus === 'success') {
      setTimeout(() => {
        //TODO - this is hard coded
        // setSpinner(false);
        navigate('/installation/' + singleProductRegister);
      }, 1000);
    }
  }, [singleSelectedProduct, singleProductStatus]);
  const handleTexaActivate = () => {
    const payload = {
      userId: userData.id,
      userEmail: userData.email,
      equipmentId: uneq?.equipmentId,
      equipmentNumber: uneq?.equipmentNumber,
      firstName: userData.firstName,
      lastName: userData.lastName,
    };
    mutate(payload);
    setactivatedPopup(true);
  };
  const handleClosed = () => {
    // dashboardQuery.useProducts();
    // setTimeout(() => navigate('/dashboard'), 300);
    setactivatedPopup(false);
    setActivatePopup(false);
    // window.location.reload();
  };
  const activedCode = localStorage.getItem('activatedCode');
  return (
    <>
      <Hidden mdDown>
        <div className={classes.unregisterDataCont}>
          <div className={classes.rotaryHeading}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} className={classes.rotaryHeadingText}>
                <input
                  // value="test"
                  type="checkbox"
                  onChange={handleChange}
                  className={classes.checkboxStyle}
                  value={uneq?.equipmentNumber}
                  checked={isSelected(uneq)}
                />

                <Grid item xs={6} md={3} className={classes.rotaryHeadingCont}>
                  <p className={classes.subHead}>Product Name</p>
                  <p className={classes.hoverText}>
                    <h2 className={classes.rotaryTitle}>{uneq?.productName}</h2>
                  </p>
                </Grid>
                <Grid item xs={6} md={3} className={classes.rotaryHeadingCont}>
                  <p className={classes.serialNum}>Serial Number</p>
                  <p className={classes.serialNumSub}>
                    {uneq?.equipmentNumber}
                  </p>
                </Grid>
                <Grid item xs={6} md={3} className={classes.rotaryHeadingCont}>
                  <p className={classes.registrationDate}>Product Code</p>
                  <p className={classes.registrationDatesub}>
                    {uneq?.productCode}
                  </p>
                </Grid>
                {/* {!checked ? (
                  <div className={classes.registerNew}>
                    <Button
                      type="submit"
                      onClick={handleClickUnRegisterEquip}
                      value={uneq?.equipmentNumber}
                    >
                      Register
                    </Button>
                  </div>
                ) : (
                  <div className={classes.registerNewDisabled}>
                    <Button disabled>Register</Button>
                  </div>
                )} */}
              </Grid>
            </Box>
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.rotaryCardContainerMobile}>
          <Accordion
            className={classes.headingSection}
            expanded={openAccordionId === uneq.equipmentNumber}
            onChange={() => handleAccordionChange(uneq.equipmentNumber)}
          >
            <div
              className={
                openAccordionId === uneq.equipmentNumber
                  ? classes.headingDetailsFocus
                  : classes.headingDetails
              }
            >
              <AccordionSummary
                expandIcon={
                  openAccordionId === uneq.equipmentNumber ? (
                    <img src={accordionMoreColorIcon} alt="accordionLessIcon" />
                  ) : isShown ? (
                    <img src={accordionMoreColorIcon} alt="accordionLessIcon" />
                  ) : !isShown ? (
                    <img
                      src={accordionMoreIcon}
                      alt="accordionMoreIcon"
                      className={classes.iconStyles}
                    />
                  ) : (
                    <img src={accordionLessIcon} alt="accordionLessIcon" />
                  )
                }
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionExpand}
              >
                <div className={classes.rotaryHeading}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={2}
                      className={classes.rotaryHeadingText}
                    >
                      {' '}
                      <input
                        // value="test"
                        type="checkbox"
                        onChange={handleChange}
                        className={classes.checkboxStyle}
                        value={uneq?.equipmentNumber}
                        checked={isSelected(uneq)}
                      />
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.rotaryHeadingCont}
                      >
                        <p className={classes.subHead}>Product Name</p>
                        <h2 className={classes.rotaryHeading}>
                          {uneq?.productName}
                        </h2>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </AccordionSummary>
            </div>
            <AccordionDetails style={{ border: '1.5px solid #CF8686' }}>
              <Typography>
                <div className={classes.cardDetailSection}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={2}
                      className={classes.rotaryHeadingText}
                    >
                      <Grid
                        item
                        xs={6}
                        md={4}
                        className={classes.rotaryHeadingCont}
                      >
                        <p className={classes.subHead}>Serial Number</p>
                        <p className={classes.subDetails}>
                          {uneq?.equipmentNumber}
                        </p>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <p className={classes.subHead}>Product Code</p>
                        <p className={classes.subDetails}>
                          {uneq?.productCode}
                        </p>
                      </Grid>
                      {/* {!checked ? (
                        <div className={classes.registerNew}>
                          <Button
                            type="submit"
                            onClick={handleClickUnRegisterEquip}
                            value={uneq?.equipmentNumber}
                          >
                            Register
                          </Button>
                        </div>
                      ) : (
                        <div className={classes.registerNewDisabled}>
                          <Button disabled>Register</Button>
                        </div>
                      )} */}
                    </Grid>
                  </Box>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Hidden>

      <div className={classes.RegisterEquipt_Modal}>
        {openRegister && (
          <RegisterEq open={openRegister} setOpen={setOpenRegister} />
        )}
      </div>
    </>
  );
};
export default forwardRef(UnregisterData);
