/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from '@Components/Button';
import classes from './RegisterEq.module.scss';
import { dashboardQuery } from '../dashboard.query';
import closeIcon from '../../../assets/closeIcon.svg';
import qrCodeIcon from '../../../assets/dashboardICons/qr-code.svg';
import addingTags from '../../../assets/dashboardICons/addTags.svg';
import addTagsDisabled from '../../../assets/dashboardICons/addTagsDisabled.svg';
import { toast } from 'react-toastify';
import useQRCodeScan from 'hooks/useQR';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import IconArtwork from '../../../assets/dashboardICons/Icon Artwork.svg';
import { getUserSFDetails } from 'utils/getUserAccount';
import { spinnerStore } from '@Store/spinnerStore';
import { useRef } from 'react';
import commonLimit from 'common/commonLimits';
const style = {
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  // Adding media query..
  '@media (min-width: 768px)': {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '667px',
    height: '730px',
  },

  '@media (orientation: landscape)': {
    // backgroundColor: '#000',
  },

  '@media (min-width:769px) and (min-width:900px)': {
    width: '880px',
    height: '500px',
  },
  '@media (min-width:900px)': {
    width: '910px',
    height: '439px',
  },
};
const modalTitle = {
  backgroundColor: '#000',
  color: '#fff',
  padding: '5px 20px 5px 20px ',
  display: 'flex',
  justifyContent: 'space-between',
};
const paraGraph = {
  fontSize: '14px',
  padding: '10px',
};

const RegisterEq = ({ open, setOpen }) => {
  const user = getUserSFDetails();
  const { startQrCode, decodedQRData, stopQrCode, uploadQrimage } =
    useQRCodeScan({
      qrcodeMountNodeID: 'qrcodemountnode',
    });
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { setSpinner, isActive } = spinnerStore();
  const [openPopUp, setOpenPopUp] = useState(false);

  const [payload, setPayload] = useState('');

  const { mutate, status, data, isFetching } =
    dashboardQuery.useMutationProduct();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const alphaNumicRegx = /\/\/(.+)$/;
  const [finalData, setFinalData] = useState('');
  const [manageState, setManageState] = useState({
    value: '',
    isError: false,
    isDecodeed: false,
  });
  const [alertMessageFlag, setAlertMessageFlag] = useState(true);
  const [tags, setTags] = useState([]);
  const [equipId, setEquipId] = useState([]);
  const [serialNumber, setSerialNumber] = useState('');
  const [recheck, setReCheck] = useState([]);
  console.warn(recheck, 'recheck123');
  const handleSearch = () => {
    setAlertMessageFlag(false);
    setPayload(tags);
    setTimeout(() => {
      mutate(tags);
      navigate('/installation/' + tags);
    }, 100);
  };
  const startQrScanS = () => {
    setOpenPopUp(true);
    setTimeout(() => {
      startQrCode();
    }, 300);
  };
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    () => {
      stopQrCode();
    };
  }, []);

  useEffect(() => {
    const { data } = decodedQRData;
    const url = data;
    const urlParams = new URLSearchParams(url);
    const paramValue = urlParams.get('snum');
    const serialNumberInfo = paramValue ? paramValue : data;
    const isValidData = alphaNumicRegx.test(serialNumberInfo || '');
    const serialNo = !isValidData ? serialNumberInfo : '';
    if (tags.length + 1 > commonLimit.multiRegistrationLimit) {
      toast.error('Registration is limited to 10 serial numbers at once.', {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setManageState({
        ...manageState,
        value: serialNo,
        isError: data ? isValidData : false,
        isDecodeed: !!serialNo,
      });
      setFinalData(serialNo);
      const equipmentData = [];
      if (serialNo) {
        const finalData = {
          serialNumber: serialNo,
          triggerEmail: shouldTriggerEmail(serialNo),
        };
        equipmentData.push(finalData);
        mutate(equipmentData);
      }
      if (data && openPopUp) {
        setTimeout(() => {
          setOpenPopUp(false);
        }, 300);
      }
    }
  }, [decodedQRData]);
  const [texaSerialNum, setTexaSerialNum] = useState([]);
  const [isStartingWithS, setIsStartingWithS] = useState(false);
  useEffect(() => {
    if (data && status === 'success') {
      if (
        data.length <= 1 &&
        data.length <= commonLimit.multiRegistrationLimit
      ) {
        localStorage.removeItem('statusId');
        if (data[0].statusId === 2) {
          if (equipId.includes(data[0].equipmentId.toUpperCase())) {
            toast.error(
              data[0].equipmentNumber + ' is a duplicate please correct',
              {
                position: toast.POSITION.TOP_CENTER,
              },
            );
          } else if (data[0].isTexaProduct === true && tags.length >= 1) {
            setTexaSerialNum([data[0].equipmentNumber]);
            toast.error(
              'Texa equipment ' +
                data[0].equipmentNumber +
                'must be registered separately and cannot be included in multiple registrations with other equipment.',
              {
                position: toast.POSITION.TOP_CENTER,
              },
            );
          } else if (texaSerialNum.length >= 1) {
            toast.error(
              'Texa equipment must be registered separately and cannot be included in multiple registrations with other equipment.',
              {
                position: toast.POSITION.TOP_CENTER,
              },
            );
          } else {
            if (data[0].isTexaProduct === true) {
              setTexaSerialNum([data[0].equipmentNumber]);
            }
            setTimeout(() => {
              //TODO - this is hard coded
              // navigate('/installation/' + (payload || serialNumber || finalData));
              setEquipId([...equipId, data[0].equipmentId.toUpperCase()]);
              setTags([...tags, data[0].equipmentNumber]);
            }, 1000);
          }
        } else {
          if (data[0].statusId === 1) {
            if (user.userRole === 'CSR' && tags.length < 1) {
              setTimeout(() => {
                //TODO - this is hard coded
                navigate('/installation/' + data[0].equipmentNumber);
              }, 100);
              // toast.error(
              //   'The serial number ' +
              //     data[0].equipmentNumber +
              //     ' has already been registered. If you believe this is an error, please contact customer support for further assistance.',
              //   {
              //     position: toast.POSITION.TOP_CENTER,
              //   },
              // );
              localStorage.setItem(
                'statusId',
                JSON.stringify(data[0].statusId),
              );
              localStorage.setItem('CheckedValue', data[0].equipmentNumber);
            } else {
              toast.error(
                'The serial number ' +
                  data[0].equipmentNumber +
                  ' has already been registered. If you believe this is an error, please contact customer support for further assistance.',
                {
                  position: toast.POSITION.TOP_CENTER,
                },
              );
            }
          }
          if (data[0].statusId === 3) {
            toast.error(
              data[0].equipmentNumber +
                ' is assigned to a different distributor and cannot be registered. Please ensure you are registering equipment assigned to your account.',
              {
                position: toast.POSITION.TOP_CENTER,
              },
            );
          }
          if (data[0].statusId === 0) {
            // toast.error(data[0].equipmentNumber + ' ' + data[0].statusMessage, {
            //   position: toast.POSITION.TOP_CENTER,
            // });
            const serialNum = data[0].equipmentNumber;
            setSerialNumber(...serialNumber, serialNum);
            const indexMatch = recheck.findIndex((item) => {
              return (
                data[0].equipmentNumber.toLowerCase() ===
                item.serialNumber.toLowerCase()
              );
            });
            if (indexMatch >= 0) {
              const matchedItem = recheck[indexMatch];
              matchedItem.tries = matchedItem.tries + 1;
              // setCount(count + 1);
              if (
                matchedItem.tries >= commonLimit.serialNumberMaxTriesForEmail
              ) {
                toast.error(
                  data[0].equipmentNumber +
                    ' ' +
                    'The technical support group at technicalsupport@vsgdover.com has been notified about an issue with the entered serial number.',

                  {
                    position: toast.POSITION.TOP_CENTER,
                  },
                );
              } else {
                toast.error(
                  data[0].equipmentNumber +
                    ' ' +
                    'Equipment wasn’t found. Please manually enter the serial number.',
                  {
                    position: toast.POSITION.TOP_CENTER,
                  },
                );
              }
            } else {
              const serialNumStore = { serialNumber: serialNum, tries: 1 };
              setReCheck((oldData) => {
                return [...oldData, serialNumStore];
              });
              toast.error(
                data[0].equipmentNumber +
                  ' Equipment wasn’t found. Please manually enter the serial number.',
                {
                  position: toast.POSITION.TOP_CENTER,
                },
              );
            }
          }
        }
      } else if (texaSerialNum.length > 1) {
        toast.error(
          'Texa equipment must be registered separately and cannot be included in multiple registrations with other equipment.',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        );
      } else if (data.length > commonLimit.multiRegistrationLimit) {
        toast.error(
          'Equipment selection  or registration  is limited to 10 at once .',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        );
      } else {
        for (let i = 0; i < data.length; i++) {
          if (data[i].statusId === 2) {
            if (equipId.includes(data[i].equipmentId.toUpperCase())) {
              toast.error(
                data[i].equipmentNumber + ' is a duplicate please correct',
                {
                  position: toast.POSITION.TOP_CENTER,
                },
              );
            } else if (data[i].isTexaProduct === true) {
              // setTexaSerialNum(data[i].equipmentNumber);
              toast.error(
                'Texa equipment ' +
                  data[i].equipmentNumber +
                  ' must be registered separately and cannot be included in multiple registrations with other equipment.',
                {
                  position: toast.POSITION.TOP_CENTER,
                },
              );
            } else {
              setTimeout(() => {
                setEquipId((equipId) => [
                  ...new Set(equipId),
                  data[i].equipmentId.toUpperCase(),
                ]);
                setTags((tags) => [
                  ...new Set(tags),
                  data[i].equipmentNumber.toUpperCase(),
                ]);
                return;
              }, 1000);
            }

            // setTags([...tags, data[i].equipmentNumber]);
          } else if (data[i].statusId === 1) {
            toast.error(
              'The serial number ' +
                data[i].equipmentNumber +
                ' has already been registered. If you believe this is an error, please contact customer support for further assistance.',
              {
                position: toast.POSITION.TOP_CENTER,
              },
            );
          } else if (data[i].statusId === 0) {
            // toast.error(data[i].equipmentNumber + ' ' + data[i].statusMessage, {
            //   position: toast.POSITION.TOP_CENTER,
            // });
            const serialNum = data[i].equipmentNumber;
            setSerialNumber(...serialNumber, serialNum);

            const indexMatch = recheck.findIndex((item) => {
              return (
                data[i].equipmentNumber.toUpperCase() ===
                item.serialNumber.toUpperCase()
              );
            });
            if (indexMatch >= 0) {
              const matchedItem = recheck[indexMatch];
              matchedItem.tries = matchedItem.tries + 1;
              console.warn(recheck, 'serialNum786');
              if (
                matchedItem.tries >= commonLimit.serialNumberMaxTriesForEmail
              ) {
                toast.error(
                  'The technical support group at technicalsupport@vsgdover.com has been notified about an issue with the entered serial number ' +
                    data[i].equipmentNumber,
                  {
                    position: toast.POSITION.TOP_CENTER,
                  },
                );
              } else {
                toast.error(
                  data[i].equipmentNumber +
                    ' Equipment wasn’t found. Please manually enter the serial number.',
                  {
                    position: toast.POSITION.TOP_CENTER,
                  },
                );
              }
            } else {
              const serialNumStore = { serialNumber: serialNum, tries: 1 };
              setReCheck((oldData) => {
                return [...oldData, serialNumStore];
              });
              toast.error(
                data[i].equipmentNumber +
                  ' Equipment wasn’t found. Please manually enter the serial number.',
                {
                  position: toast.POSITION.TOP_CENTER,
                },
              );
            }
          }
        }
      }
    }

    if (data === null && status === 'success') {
      toast.error('Please Enter Valid Serial Number', {
        position: toast.POSITION.TOP_CENTER,
      });
      setEquipId('');
      setTags('');
    }
    console.warn(texaSerialNum, 'setTexaSerialNum');
  }, [data, status]);

  localStorage.setItem('distributorId', JSON.stringify(data?.distributorId));

  const handleupload = (e) => {
    if (e.target.files.length === 0) {
      // No file selected, ignore
      return;
    }
    console.warn(e, 'uploadQrcode');
    setOpenPopUp(true);
    const imageFile = e.target.files[0];
    setTimeout(() => {
      uploadQrimage(imageFile);
      console.warn(decodedQRData, 'uploadQrimage');
      e.target.value = '';
    }, 300);
  };

  const handleClosePop = () => setOpen(false);

  const handleClose = () => {
    setOpenPopUp(false);
    stopQrCode();
  };

  // const handleChange = (val) => {
  //   setSerialNumber(val.target.value);
  // };

  // const isInputValid = () => {
  //   const regex = /^[a-zA-Z0-9,]*$/;
  //   return regex.test(tags);
  // };
  const isLoading = status === 'loading';
  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
  }, [isLoading]);

  function removeTag(index) {
    setTexaSerialNum([]);
    setEquipId(equipId.filter((el, i) => i !== index));
    setTags(tags.filter((el, i) => i !== index));
  }
  function shouldTriggerEmail(serialNumb) {
    const indexMatch = recheck.findIndex((item) => {
      return serialNumb.toUpperCase() === item.serialNumber.toUpperCase();
    });
    if (indexMatch >= 0) {
      const serialNumData = recheck[indexMatch];
      return (
        serialNumData.tries === commonLimit.serialNumberMaxTriesForEmail - 1
      );
    }
    return false;
  }
  const [isinputValue, setInputValue] = useState(false);
  const [isValid, setIsValid] = useState(true); // To keep track of validity
  // Regex pattern for validation
  const regex = /^[a-zA-Z0-9,]*$/;
  const handleInputChange = (event) => {
    const value = event.target.value;
    value.length >= 2 ? setInputValue(true) : setInputValue(false);
    if (value !== '') {
      // const validation = regex.test(value);
      regex.test(value) ? setIsValid(true) : setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  function handleClick(index) {
    if (inputRef.current.value === '') return;
    const value = inputRef.current.value.toUpperCase();
    const values = value.split(',').filter((item) => {
      return item.length > 0;
    });
    const filterValue = filterDuplicate([...new Set(values)]);
    function filterDuplicate(serialNumCheck) {
      let filteredEquipments = [];
      for (let i = 0; i < serialNumCheck.length; i++) {
        const equipSerial = serialNumCheck[i];
        let currentduplicateDataMatch = filteredEquipments.filter((item) => {
          let filteredEquipmentNumber = item.toLowerCase();
          let newEquipmentNumber = equipSerial.toLowerCase();

          if (filteredEquipmentNumber.startsWith('s')) {
            if (!newEquipmentNumber.startsWith('s')) {
              newEquipmentNumber = 's' + newEquipmentNumber;
            }
          }

          if (newEquipmentNumber.startsWith('s')) {
            if (!filteredEquipmentNumber.startsWith('s')) {
              filteredEquipmentNumber = 's' + filteredEquipmentNumber;
            }
          }

          return filteredEquipmentNumber === newEquipmentNumber;
        });
        console.warn(currentduplicateDataMatch, 'filteredEquipmentNumber');
        if (currentduplicateDataMatch.length > 0) {
          toast.error(
            'S' + filteredEquipments + ' is a duplicate please correct',
            {
              position: toast.POSITION.TOP_CENTER,
            },
          );
        } else {
          filteredEquipments.push(equipSerial);
        }
      }
      return filteredEquipments;
    }
    console.warn(values, filterValue, 'filteredValue786');
    const lengthCheck = filterValue.length + tags.length;
    console.warn(lengthCheck, 'filterValue123');
    if (lengthCheck > commonLimit.multiRegistrationLimit) {
      toast.error('Registration is limited to 10 serial numbers at once.', {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const equipmentData = [];
      filterValue.forEach((item) => {
        const finalData = {
          serialNumber: item,
          triggerEmail: shouldTriggerEmail(item),
        };
        equipmentData.push(finalData);
      });

      setAlertMessageFlag(false);
      setPayload(equipmentData);
      setTimeout(() => {
        mutate(equipmentData);
      }, 100);
    }
    // if (!value.trim()) return;
    // if (data.statusId === 2) {
    //   setTags([...tags, value]);
    // }
    // } else if (data === null && status === 'success') {
    //   toast.error('Please Enter Valid Serial Number', {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    //   inputRef.current.value = '';
    // }
    setInputValue(false);
    inputRef.current.value = '';
  }
  console.warn(tags, equipId, 'tagsValue');
  localStorage.removeItem('CheckedValues');
  if (tags.length >= 1) {
    localStorage.setItem('CheckedValue', tags);
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClosePop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.RegisterEquipt_Modal}>
          <p style={modalTitle}>
            <p className={classes.alignItems}>
              <p>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className={classes.textFontSize}
                >
                  New Equipment Registration
                </Typography>
              </p>
              <p className={classes.subTitle}>
                (Please provide the Serial Number or Scan / Upload QR / Bar Code
                to register the new equipment){' '}
              </p>
            </p>
            <p className={classes.iconStyles}>
              <Link onClick={handleClosePop} className={classes.closeIcon}>
                <img src={closeIcon} alt="closeIcon" />
              </Link>
            </p>
          </p>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            component="div"
            style={paraGraph}
          >
            <div className={classes.registerNewEquipPopup}>
              <div className={classes.leftContainer}>
                <p>QR / Bar Code Scanner</p>
                <div
                  className={classes.scannerImg}
                  onClick={startQrScanS}
                  role="button"
                >
                  <img src={qrCodeIcon} alt="QR-Code" />
                  <p>
                    <Link>Scan QR / Bar Code</Link>
                  </p>
                </div>
                <p className={classes.orText}>(OR)</p>
                <Button variant="outlined" className={classes.buttonStyles}>
                  <label name="qr-input-file" className={classes.labelStyles}>
                    Upload QR / Bar Code Image
                    <input
                      type="file"
                      id="qr-input-file"
                      accept="image/*"
                      onChange={handleupload}
                      // capture="filesystem"
                      className={classes.fileUploadButton}
                    ></input>
                  </label>
                </Button>
                <Dialog
                  open={openPopUp}
                  maxWidth={!fullScreen ? 'sm' : ''}
                  fullWidth={true}
                  fullScreen={fullScreen}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    className={classes.scanQRCode}
                  >
                    {'Scan Qr Code'}
                  </DialogTitle>
                  <DialogContent
                    sx={{ minHeight: '250px' }}
                    className={classes.dialogContent}
                  >
                    <div
                      id="qrcodemountnode"
                      style={{ width: '330px', height: '330px' }}
                    ></div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClose}
                      className={classes.scanButton}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <p className={classes.hoverText}>
                  <img src={IconArtwork} alt="IconArtwork" />
                  <span className={classes.tooltip}>
                    Serial number and QR / Bar code is located on the product
                    information tag, normally located near the power unit,
                    control area or side of the equipment.
                  </span>
                  Hover to view instructions of how to Scan / Upload QR / Bar
                  Code
                </p>
              </div>
              <div className={classes.rightContainer}>
                <h6 className={classes.hoverText}>
                  Search Serial Numbers{' '}
                  <img src={IconArtwork} alt="IconArtwork" />
                  <p className={classes.hoverTextInP}>
                    <span className={classes.tooltip}>
                      Please enter one or more serial numbers, separated by
                      commas, in the input field. Once entered, click '+' to
                      include them in the list.
                    </span>{' '}
                  </p>
                </h6>
                <div className={classes.tags_input_container}>
                  <input
                    // onKeyDown={handleKeyDown}
                    ref={inputRef}
                    type="text"
                    className={classes.tags_input}
                    placeholder="Enter valid serial numbers"
                    onChange={handleInputChange}
                    style={{
                      borderColor: isValid ? '#000' : '#CE1F1F',
                      color: isValid ? '#000' : '#CE1F1F',
                    }}
                    min="2"
                  />{' '}
                  <button
                    onClick={handleClick}
                    className={classes.addingTags}
                    disabled={!isinputValue}
                  >
                    <img
                      src={isinputValue ? addingTags : addTagsDisabled}
                      alt="addingSerialNumbers"
                    />
                  </button>
                  {tags.map((tag, index) => (
                    <div className={classes.tag_item} key={index}>
                      <span className={classes.text}>{tag}</span>

                      <span
                        role="button"
                        className={classes.close}
                        onClick={() => removeTag(index)}
                      >
                        &times;
                      </span>
                    </div>
                  ))}
                </div>{' '}
                {!isValid && (
                  <p
                    style={{
                      color: '#c51e1e ',
                      fontSize: '12px',
                    }}
                  >
                    Special Characters are not allowed!
                  </p>
                )}
                {/* {!isInputValid() && (
                  <div
                    style={{
                      color: '#c51e1e ',
                      fontSize: '12px',
                    }}
                  >
                    Special Characters are not allowed!
                  </div>
                )} */}
                <div className={classes.searchButton}>
                  <Button
                    type="submit"
                    onClick={handleSearch}
                    disabled={tags.length < 1}
                  >
                    Submit
                  </Button>
                </div>
                {/* <div>
                  <div className={classes.userNameCnt}>
                    <InputField
                      label="Search Serial Number"
                      type="text"
                      name="serialNumber"
                      onChange={(val) => {
                        handleChange(val);
                      }}
                      placeholder="Enter valid serial number"
                      value={serialNumber.trim()}
                      className={!isInputValid() && classes.fieldRow}
                    />
                  </div>
                  {!isInputValid() && (
                    <div
                      style={{
                        color: '#c51e1e ',
                        marginTop: '-15px',
                        fontSize: '12px',
                      }}
                    >
                      Special Characters are not allowed!
                    </div>
                  )}
                  <div className={classes.searchButton}>
                    <Button
                      type="submit"
                      onClick={handleSearch}
                      disabled={
                        serialNumber.length <= 3 ||
                        isFetching ||
                        !isInputValid()
                      }
                    >
                      SEARCH
                    </Button>
                  </div>
                  {!decodedQRData.data && data?.statusId === 2 && (
                    <p className={classes.notifyMessageStyles}>
                      Redirecting, please wait..
                    </p>
                  )}
                </div> */}
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default React.memo(RegisterEq);
